import { Box, Flex, Hide, Show, Stack } from '@chakra-ui/react';

import { createOutbound, requestNewOutbound } from 'api/Dashboard/outbounds';
import BackToListButton from 'components/Dashboard/BackToListButton';
import OutboundForm from 'components/Dashboard/Outbounds/OutboundForm';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import { outboundTypeEnum } from 'constants/dashboard';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { adjustToUTC } from 'utils/date';
import { flattenErrorObject } from 'utils/formError';
import { mapSelectListToFormOptions } from 'utils/select';

const OutboundCreate = () => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();
  const navigate = useNavigate();

  const { data = {}, refetch } = useQuery(
    ['request-new-outbound'],
    requestNewOutbound
  );

  const {
    mutate: createOutboundMutation,
    isLoading: isCreateOutboundLoading,
    error,
  } = useMutation(createOutbound, {
    onSuccess: (data) => {
      navigate(`/outbounds/${data.data.id}/assign`);
    },
  });

  const outboundTypeOptions = data.select_options?.outbound_types.map(
    ([label, value]) => {
      const labelKey = Object.keys(outboundTypeEnum).find(
        (key) => outboundTypeEnum[key] === value
      );

      return {
        label: t(`orderCarrier.${labelKey}`),
        value,
      };
    }
  );

  const destinationOptions = mapSelectListToFormOptions(
    data.select_options?.destinations
  );

  const warehouseOptions = data.select_options?.warehouses.map((warehouse) => ({
    label: warehouse.name,
    value: warehouse.address_id,
    id: warehouse.id,
  }));

  const handleSubmit = (data) => {
    const payload = {
      ...data,
      schedule_at: adjustToUTC(data.schedule_at),
    };

    createOutboundMutation(payload);
  };

  return (
    <Stack spacing={6}>
      <Box>
        <Show below="md">
          <Box mb={2} mt={4}>
            <PageTitle title={pageTitle} fontWeight={600} />
          </Box>
        </Show>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Hide below="md">
            <BackToListButton title={t('outboundsList')} url="/outbounds" />
          </Hide>
        </Flex>
      </Box>

      <OutboundForm
        onSubmit={handleSubmit}
        outboundTypeOptions={outboundTypeOptions}
        destinationOptions={destinationOptions}
        warehouseOptions={warehouseOptions}
        isLoading={isCreateOutboundLoading}
        backendErrors={flattenErrorObject(error?.response?.data.errors)}
        onAddNewSuccess={async () => {
          await refetch();
        }}
      />
    </Stack>
  );
};

export default OutboundCreate;
