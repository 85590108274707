import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { getWarehouses } from 'api/Dashboard/warehouses';
import { Spinner } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DotsHorizontalIcon } from 'assets/icons/dots-horizontal.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as PalletIcon } from 'assets/icons/pallet.svg';
import { ReactComponent as DownloadContractIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as CertificateIcon } from 'assets/icons/certificate.svg';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';
import { ReactComponent as DryIcon } from 'assets/icons/dry.svg';
import { ReactComponent as AirConditionedIcon } from 'assets/icons/tempControlled.svg';
import { ReactComponent as Chilled } from 'assets/icons/chilled.svg';
import { ReactComponent as FrozenIcon } from 'assets/icons/frozen.svg';
import { ReactComponent as SpaceRentedIcon } from 'assets/icons/maximize.svg';
import Carousel from 'components/shared/Carousel';
import { formatDate } from 'utils/date';
import styled from '@emotion/styled';
import StatusTag from 'components/Dashboard/StatusTag';
import TablePage from 'components/Dashboard/TablePage';
import EmptyTable from 'components/Dashboard/EmptyTable';
import { ReactComponent as WarehouseEmptyTable } from 'assets/images/warehouses-empty-table.svg';
import { useOutletContext } from 'react-router-dom';
import { ReactComponent as QuotationsIcon } from 'assets/icons/bill.svg';

const columnHelper = createColumnHelper();

const StyledSpaceRentedIcon = styled(SpaceRentedIcon)`
  path {
    stroke: #718096;
  }
`;

const WhiteQuotationsIcon = styled(QuotationsIcon)`
  path {
    stroke: #fff;
  }
`;

const ActionsMenu = ({ warehouseId, isMobile }) => {
  const { t } = useTranslation();

  return (
    <Menu size="lg">
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={isMobile ? <DotsVerticalIcon /> : <DotsHorizontalIcon />}
        variant={isMobile ? 'solid' : 'outline'}
        size="sm"
      />
      <MenuList>
        <MenuItem
          icon={<EyeIcon width="24" height="24" />}
          onClick={() => console.log({ view: warehouseId })}
          textTransform="capitalize"
        >
          {t('viewQuote')}
        </MenuItem>
        <MenuItem
          icon={<DownloadContractIcon width="24" height="24" />}
          onClick={() => console.log({ download: warehouseId })}
          textTransform="capitalize"
        >
          {t('downloadContract')}
        </MenuItem>
        <MenuItem
          icon={<CertificateIcon width="24" height="24" />}
          onClick={() => console.log({ download: warehouseId })}
          textTransform="capitalize"
        >
          {t('requestLicenseCopy')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export const storageTypes = {
  dry: { icon: <DryIcon width="24px" height="24px" />, label: 'dry' },
  air_conditioned: {
    icon: <AirConditionedIcon width="24px" height="24px" />,
    label: 'tempControlled',
  },
  refrigerated: {
    icon: <Chilled width="24px" height="24px" />,
    label: 'chilled',
  },
  frozen: { icon: <FrozenIcon width="24px" height="24px" />, label: 'frozen' },
};

export const WarehousesDesktopTable = ({ warehouseList }) => {
  const { t } = useTranslation();

  return warehouseList.map(
    ({
      warehouse_id,
      name,
      alias_name,
      operated_by,
      map_url,
      starting_date,
      storage_type,
      ending_date,
      pallets_rented,
      space_rented,
      agreement_interval,
      auto_renewal,
      image_urls,
    }) => {
      const storageType = storageTypes[storage_type];

      return (
        <Card
          padding={6}
          borderRadius="xl"
          key={warehouse_id}
          flexDirection="row"
          gap={7}
        >
          <Box
            borderRadius="8px"
            overflow="hidden"
            style={{
              width: '320px',
              height: '180px',
            }}
          >
            <Carousel
              slides={image_urls}
              imageProps={{
                height: '180px',
                width: '320px',
              }}
            />
          </Box>

          <Box flex={1}>
            <Box>
              <Flex justifyContent="space-between">
                <Text
                  fontWeight={700}
                  fontSize="lg"
                  color="gray.800"
                  marginBottom={2}
                  marginTop={4}
                >
                  {alias_name ?? name}
                </Text>
                <Flex gap={2}>
                  {/* <Button
                    variant="outline"
                    color="gray.600"
                    textTransform="capitalize"
                    fontWeight={600}
                    size="sm"
                  >
                    {t('viewOnMap')}
                  </Button>

                  <ActionsMenu warehouseId={warehouse_id} /> */}
                </Flex>
              </Flex>
              <Text
                fontWeight={500}
                fontSize="sm"
                color="gray.700"
                marginBottom={3}
              >
                <Text textTransform="capitalize" as="span">
                  {`${t('by')} `}
                </Text>
                {operated_by}
              </Text>
            </Box>

            <Flex fontSize="sm" fontWeight={500} color="gray.700">
              <Flex
                flexDirection="column"
                gap={2}
                paddingInlineEnd={8}
                minWidth="250px"
              >
                <Flex alignItems="center" gap={2}>
                  <CalendarIcon width="24px" height="24px" />
                  <Text noOfLines={1}>
                    {formatDate(starting_date)} - {formatDate(ending_date)}
                  </Text>
                </Flex>
                <Flex alignItems="center" gap={2}>
                  {storageType && (
                    <>
                      {storageType.icon}
                      {t(storageType.label)}
                    </>
                  )}
                </Flex>
              </Flex>

              <Divider
                width="2px"
                orientation="horizontal"
                height="auto"
                bg="gray.300"
                borderRadius="md"
              />

              <Flex gap={2} paddingInlineStart={8}>
                <Flex justifyContent="center" flexDirection="column" gap={2}>
                  <PalletIcon width="24px" height="24px" />
                  <StyledSpaceRentedIcon width="24px" height="24px" />
                </Flex>
                <Flex
                  justifyContent="center"
                  flexDirection="column"
                  gap={2}
                  fontWeight={500}
                  color="gray.700"
                >
                  <Text flex={1}>{pallets_rented}</Text>
                  <Text flex={1}>{space_rented}</Text>
                </Flex>
                <Flex
                  alignItems="center"
                  flexDirection="column"
                  gap={2}
                  color="gray.600"
                  textTransform="capitalize"
                >
                  <Text flex={1}>{t('palletRented')}</Text>
                  <Text flex={1}>{t('spaceRented')}</Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex marginTop={3} gap={2}>
              <Box>
                {agreement_interval && (
                  <StatusTag colorScheme="blue" size="sm">
                    {t(agreement_interval)}
                  </StatusTag>
                )}
              </Box>
              {auto_renewal && (
                <Box>
                  <StatusTag colorScheme="green" size="sm">
                    {t('autoRenewal')}
                  </StatusTag>
                </Box>
              )}
            </Flex>
          </Box>
        </Card>
      );
    }
  );
};

export const WarehousesMobileTable = ({ warehouseList, isLoading }) => {
  const { t } = useTranslation();

  const columns = [
    columnHelper.accessor('alias_name', {
      cell: ({ getValue, row }) => getValue() ?? row.original.name,
      header: t('name'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('auto_renewal', {
      cell: ({ getValue }) => {
        const isAutoRenewal = getValue();

        if (!isAutoRenewal) {
          return null;
        }

        return (
          <StatusTag colorScheme="green" size="sm">
            {t('autoRenewal')}
          </StatusTag>
        );
      },
      header: t('status'),
      meta: {
        mobileHeader: 'right',
        fitContent: true,
      },
    }),
    // columnHelper.accessor('actions', {
    //   cell: ({ row }) => {
    //     return <ActionsMenu warehouseId={row.original.warehouse_id} isMobile />;
    //   },
    //   header: t('actions'),
    //   meta: {
    //     isAction: true,
    //   },
    // }),
    columnHelper.accessor('operated_by', {
      cell: ({ getValue }) => getValue(),
      header: t('operatedBy'),
    }),
    columnHelper.accessor('storage_type', {
      cell: ({ getValue }) => {
        const storageType = storageTypes[getValue()];

        if (!storageType) {
          return null;
        }

        return t(storageType.label);
      },
      header: t('type'),
    }),
    columnHelper.accessor('agreement_interval', {
      cell: ({ getValue }) => (
        <StatusTag colorScheme="blue" size="sm">
          {t(getValue())}
        </StatusTag>
      ),
      header: t('agreement'),
    }),
    columnHelper.accessor('starting_date', {
      cell: ({ getValue }) => formatDate(getValue()),
      header: t('startingDate'),
    }),
    columnHelper.accessor('ending_date', {
      cell: ({ getValue }) => formatDate(getValue()),
      header: t('endingDate'),
    }),
    columnHelper.accessor('space_rented', {
      cell: ({ getValue }) => `${getValue()} ${t('M2')}`,
      header: t('spaceRented'),
    }),
    columnHelper.accessor('pallets_rented', {
      cell: ({ getValue }) => getValue(),
      header: t('palletRented'),
    }),
  ];

  return (
    <TablePage
      data={warehouseList}
      columns={columns}
      title={t('warehousesList')}
      searchPlaceholder="Search by ID"
      isLoading={isLoading}
      hideSearch
      hidePagination
    />
  );
};

const Warehouses = () => {
  const { t } = useTranslation();

  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  const { onRequestQuote, onShowRequestQuoteButton } = useOutletContext();

  const { data: warehouseResponse, isLoading } = useQuery(
    'warehouse-list',
    getWarehouses,
    {
      refetchOnMount: true,
      onSuccess: (response) => {
        onShowRequestQuoteButton(!!response.data?.length);
      },
    }
  );
  const warehouseList = warehouseResponse?.data ?? [];

  if (isLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  if (!warehouseList.length) {
    return (
      <EmptyTable
        illustration={<WarehouseEmptyTable />}
        title={t('emptyStateTitle', {
          value: t('Warehouses'),
        })}
        subtitle={t('requestAndApproveQuoteQuoteAndComeBack')}
        actions={
          <Button
            leftIcon={<WhiteQuotationsIcon />}
            colorScheme="primary"
            textTransform="capitalize"
            size="lg"
            width="full"
            onClick={onRequestQuote}
          >
            {t('requestQuote')}
          </Button>
        }
        noPadding
      />
    );
  }

  return (
    <Stack spacing={6}>
      {isMobile ? (
        <WarehousesMobileTable
          warehouseList={warehouseList}
          isLoading={isLoading}
        />
      ) : (
        <WarehousesDesktopTable warehouseList={warehouseList} />
      )}
    </Stack>
  );
};

export default Warehouses;
