import {
  addSkuToOutbound,
  editOutboundSKU,
  getOutboundSKUs,
  removeOutboundSKU,
} from 'api/Dashboard/outbounds';
import useTable from 'hooks/useTable';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TablePage from '../TablePage';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';
import Select from '../Select';
import { useMutation } from 'react-query';
import PageDrawer from '../PageDrawer';
import { skuQuantityValidation } from 'constants/validation';
import SKUsTableForm from '../Orders/SKUsTableForm';
import { packagingEnum } from 'constants/dashboard';
import DebouncedNumberInput from 'components/shared/Inputs/DebouncedNumberInput';
import EmptyTable from '../EmptyTable';
import { ReactComponent as SKUsEmptyTable } from 'assets/images/skus-empty-table.svg';

const columnHelper = createColumnHelper();

const DuplicateFromOutboundsDrawer = ({ isOpen, onClose, isLoading }) => {
  const { t } = useTranslation();

  return (
    <PageDrawer
      title={t('duplicateSKUFromPreviousOutbound')}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      formId="duplicateFromOutbounds"
      isLoading={isLoading}
      size="xl"
    >
      Form
    </PageDrawer>
  );
};

const SKUsSection = ({ outboundId, status, onUpdateSuccess }) => {
  const { t } = useTranslation();

  const { isOpen, onClose } = useDisclosure();

  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  const fetchFn = useCallback(
    (searchParams) => getOutboundSKUs(outboundId, searchParams),
    [outboundId]
  );

  const packagingOptions = Object.keys(packagingEnum).map((key) => ({
    label: t(key),
    value: packagingEnum[key],
  }));

  const {
    data = [],
    refetch,
    isLoading,
    onSearchChange,
    onSortingChange,
    onPaginationChange,
    pagination,
  } = useTable({
    fetch: fetchFn,
    fetchKey: ['outbound-skus', outboundId],
    searchKey: 'sku_merchant_sku_or_sku_system_sku_or_sku_name_cont',
    disableURLParams: true,
  });

  const [editItemID, setEditItemID] = useState(undefined);

  const isDraft = status === 'draft';

  const {
    mutate: editOutboundSKUMutation,
    isLoading: isEditOutboundSKULoading,
  } = useMutation(editOutboundSKU, {
    onSuccess: (data) => {
      refetch();
    },
  });

  const { mutate: addSkuToOutboundMutation, error: addSkuToOutboundError } =
    useMutation((payload) => addSkuToOutbound(outboundId, payload), {
      onSuccess: (data) => {
        refetch();
        onUpdateSuccess();
      },
    });

  const { mutate: removeOutboundSKUMutation, isLoading: isRemoveSKULoading } =
    useMutation((skuID) => removeOutboundSKU({ id: outboundId, skuID }), {
      onSuccess: (data) => {
        refetch();
        onUpdateSuccess();
      },
    });

  const handleSkuChange = (skuID, outbound_sku) => {
    const payload = {
      id: outboundId,
      skuID,
      outbound_sku,
    };

    editOutboundSKUMutation(payload);
  };

  const draftColumns = [
    columnHelper.accessor('requested.packaging', {
      cell: ({ getValue, row }) => {
        const id = row.original.id;
        if (id === editItemID) {
          const value = getValue();

          const defaultValue = { label: t(value), value: packagingEnum[value] };

          return (
            <Select
              placeholder={t('packagingType')}
              options={packagingOptions}
              onChange={({ value }) => {
                handleSkuChange(id, {
                  packaging: value,
                  quantity: row.original.requested.quantity,
                });
              }}
              isDisabled={isEditOutboundSKULoading}
              defaultValue={defaultValue}
            />
          );
        }
        return (
          <Text fontWeight={500} textTransform="capitalize">
            {t(getValue())}
          </Text>
        );
      },
      header: t('selectedPackaging'),
      enableSorting: false,
    }),
    columnHelper.accessor('requested.quantity', {
      cell: ({ getValue, row }) => {
        const id = row.original.id;

        if (id === editItemID) {
          const defaultValue = getValue();

          return (
            <DebouncedNumberInput
              placeholder="quantity"
              name="quantity"
              defaultValue={defaultValue}
              min={skuQuantityValidation.min}
              // max={skuQuantityValidation.max}
              onChange={(value) => {
                handleSkuChange(id, {
                  packaging: packagingEnum[row.original.requested.packaging],
                  quantity: value,
                });
              }}
              isDisabled={isEditOutboundSKULoading}
            />
          );
        }
        return <Text fontWeight={500}>{getValue()}</Text>;
      },
      header: t('selectedQuantity'),
      enableSorting: false,
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        const id = row.original.id;

        const handleDelete = () => {
          removeOutboundSKUMutation(row.original.id);
        };

        const handleEdit = () => {
          setEditItemID((prevValue) => (prevValue === id ? undefined : id));
        };

        if (isMobile) {
          return (
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<DotsVerticalIcon />}
                // size="sm"
              />
              <MenuList>
                <MenuItem textTransform="capitalize" onClick={handleEdit}>
                  {t('edit')}
                </MenuItem>
                <MenuItem
                  textTransform="capitalize"
                  color="red.500"
                  onClick={handleDelete}
                >
                  {t('remove')}
                </MenuItem>
              </MenuList>
            </Menu>
          );
        }

        return (
          <fieldset disabled={isRemoveSKULoading ? 'disabled' : undefined}>
            <Flex gap={3} justifyContent="center">
              <Button
                variant="outline"
                color="gray.600"
                textTransform="capitalize"
                fontWeight={500}
                // size="sm"
                onClick={handleEdit}
              >
                {t('edit')}
              </Button>
              <Button
                variant="outline"
                color="red.600"
                textTransform="capitalize"
                fontWeight={500}
                // size="sm"
                onClick={handleDelete}
              >
                {t('remove')}
              </Button>
            </Flex>
          </fieldset>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
        centerHeader: true,
      },
      enableSorting: false,
    }),
  ];

  const columnsRest = [
    columnHelper.accessor('', {
      cell: ({ row }) => {
        const { requested, reserved } = row.original;

        return (
          <Flex flexDirection="row" justifyContent="space-between" gap={4}>
            <Flex flexDirection="column" gap={2}>
              <Text>{`${requested?.quantity} ${requested?.packaging}`}</Text>
              <Text>{`${reserved?.quantity} ${reserved?.packaging}`}</Text>
            </Flex>

            <Flex flexDirection="column" gap={2}>
              <Text
                textTransform="uppercase"
                color="purple.500"
                fontWeight={500}
              >
                {t('requested')}
              </Text>
              <Text
                textTransform="uppercase"
                color="green.500"
                fontWeight={500}
              >
                {t('reserved')}
              </Text>
            </Flex>
          </Flex>
        );
      },
      header: t('quantityPackaging'),
      meta: {
        fitContent: true,
        centerHeader: true,
      },
      enableSorting: false,
    }),
    columnHelper.accessor('location', {
      cell: ({ getValue }) => getValue(),
      header: t('location'),
      enableSorting: false,
    }),
  ];

  const columns = [
    columnHelper.accessor('sku_name', {
      cell: ({ getValue }) => <Text fontWeight={600}>{getValue()}</Text>,
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('sku_system_sku', {
      cell: ({ getValue }) => <Text fontWeight={500}>{getValue()}</Text>,
      header: t('sirdabSKU'),
    }),
    columnHelper.accessor('sku_merchant_sku', {
      cell: ({ getValue }) => <Text fontWeight={500}>{getValue()}</Text>,
      header: t('merchantSKU'),
      enableSorting: false,
    }),
    ...(isDraft ? draftColumns : columnsRest),
  ];

  const handleSkuSubmit = (sku, onSuccessCallback) => {
    const skuPayload = {
      ...sku,
      quantity: sku.quantity,
    };

    addSkuToOutboundMutation(skuPayload, {
      onSuccess: onSuccessCallback,
    });
  };

  return (
    <>
      <TablePage
        data={data}
        columns={columns}
        pagination={pagination}
        title={<SectionTitle title={t('outboundSKUs')} hideDivider />}
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        searchPlaceholder={t('skusPages.searchPlaceholder')}
        onSearchChange={onSearchChange}
        isLoading={isLoading}
        footer={
          isDraft && (
            <Box pb={6}>
              <SKUsTableForm
                onSubmit={handleSkuSubmit}
                outboundId={outboundId}
                backendErrors={addSkuToOutboundError?.response?.data?.errors}
              />
            </Box>
          )
        }
        emptyTable={
          <EmptyTable
            illustration={<SKUsEmptyTable />}
            title={t('noSKUsYet')}
            subtitle={`${t('selectExisting', {
              value: t('theOutbound'),
            })}`}
            noPadding
          />
        }
        showEmptyInsideBody
      />
      <DuplicateFromOutboundsDrawer isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default SKUsSection;
