import axios from 'axios';
import { deleteToken, getToken, saveToken } from './token';
import { refreshToken } from 'api/auth';
import { deleteLocalStorage } from './localStorage';
import { getLocalStorageLanguage } from './i18n';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const api = axios.create({
  baseURL: API_BASE_URL,
});

api.defaults.headers.common['Content-Type'] = 'application/json';
api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// Axios request interceptor
api.interceptors.request.use(
  (config) => {
    const { token } = getToken('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.HTTP_ACCEPT_LANGUAGE = getLocalStorageLanguage();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export function setupInterceptors(updatePaymentDue) {
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error.config;

      if (error.response) {
        if (error.response.status === 402) {
          updatePaymentDue(true);
        }

        if (
          (error.response.status === 401 || error.response.status === 403) &&
          !originalConfig._retry &&
          originalConfig.url !== '/users/tokens'
        ) {
          originalConfig._retry = true;

          try {
            const { newToken, expireAt, newRefreshToken } =
              await refreshToken();

            saveToken({
              newToken,
              expireAt,
              refreshToken: newRefreshToken,
            });

            return api(originalConfig);
          } catch (error) {
            deleteToken();
            deleteLocalStorage('user');
            window.location = '/sign-in';
          }
        }
      }

      return Promise.reject(error);
    }
  );
}

export default api;
