import { useCallback, useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Text,
  useNumberInput,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const StepperInput = ({
  label,
  step,
  defaultValue = 0,
  min = 0,
  minErrorMessage,
  max,
  register,
  name,
  required,
  setValue,
  trigger,
  errors,
  validate,
  isDisabled,
}) => {
  const { t } = useTranslation();

  const formProps = register(name, {
    required,
    min: { value: min, message: `${t(minErrorMessage)} ${min}` },
    validate,
  });

  const [timeoutId, setTimeoutId] = useState(null);

  const handleChange = useCallback(
    (value) => {
      clearTimeout(timeoutId);
      setTimeoutId(
        setTimeout(() => {
          setValue(name, value, { shouldDirty: true });
          trigger(name);
        }, 500)
      );
    },
    [timeoutId, setValue, name, trigger]
  );

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step,
      defaultValue,
      min,
      max,
      name,
      onChange: handleChange,
      isDisabled,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();

  const inputProps = getInputProps();

  return (
    <FormControl width="auto" isInvalid={errors[name]} isRequired={required}>
      <Flex align="center" columnGap="2">
        <HStack
          maxW="320px"
          border="2px"
          borderColor="gray.100"
          padding="1"
          rounded="xl"
        >
          <Button {...dec} size="sm" height="30" padding="0">
            -
          </Button>
          <Input
            border="none"
            padding="1"
            width="60px"
            height="30"
            textAlign="center"
            dir="ltr"
            bg="white"
            {...inputProps}
            {...formProps}
            onChange={(e) => {
              inputProps.onChange(e);
            }}
          />
          <Button {...inc} size="sm" height="30" padding="0">
            +
          </Button>
        </HStack>
        <Text fontWeight="semibold" fontSize="sm">
          {label}
        </Text>
      </Flex>
      <FormErrorMessage>
        {errors[name] && t(errors[name].message)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default StepperInput;
