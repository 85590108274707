import api from 'utils/axios';

export const getCities = async () => {
  try {
    const response = await api('/merchants/cities/quote_cities');
    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const requestQuote = async (businessNeeds) => {
  try {
    const response = await api.post('/merchants/quotes', {
      quote: {
        ...businessNeeds,
        city_id: parseInt(businessNeeds.city_id),
        pallet_required_space: parseInt(
          businessNeeds.pallet_required_space ?? 0
        ),
        sqm_required_space: parseInt(businessNeeds.sqm_required_space ?? 0),
        sfda_license_type: businessNeeds.sfda_license_type,
      },
    });

    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getQuote = async (quoteId) => {
  try {
    const response = await api(`/merchants/quotes/${quoteId}`);
    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const initQuote = async ({ warehouseId, businessNeeds }) => {
  try {
    const response = await api.post('/merchants/quotes/init_quote', {
      quote: {
        warehouse_id: warehouseId,
        inventory_type: businessNeeds.inventory_type,
        pallet_required_space: parseInt(businessNeeds.pallet_required_space),
        sqm_required_space: parseInt(businessNeeds.sqm_required_space),
        sfda_license_type: businessNeeds.sfda_license_type,
      },
    });

    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const approveQuote = async ({ quoteId, quoteLineItems }) => {
  try {
    const response = await api.patch(`/merchants/quotes/${quoteId}`, {
      quote: {
        id: quoteId,
        quote_line_items: quoteLineItems,
        active: true,
      },
    });

    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const updateQuote = async ({ quoteId, quote }) => {
  try {
    const response = await api.patch(`/merchants/quotes/${quoteId}`, {
      quote: {
        id: quoteId,
        ...quote,
      },
    });

    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const downloadQuoteAsPDF = async (quoteId) => {
  try {
    const response = await api(`/merchants/quotes/${quoteId}/download_pdf`, {
      responseType: 'blob',
    });
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getOnboardingState = async () => {
  try {
    const response = await api('/merchants/onboarding_states');
    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const updateOnboardingState = async ({
  onboardingStateId,
  onboarding: { state, complete = false },
}) => {
  try {
    const response = await api.patch(
      `merchants/onboarding_states/${onboardingStateId}`,
      {
        onboarding: {
          complete,
          state,
        },
      }
    );

    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const verifyBusiness = async (payload) => {
  try {
    const response = await api.post('/verifications/cr_number', payload);

    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const completeOnboarding = async (payload) => {
  const formattedPayload = {
    ...payload,
    need_pickup: payload.need_pickup === 'true',
    // using undefined to avoid sending UI state to the onboarding complete
    skus: payload.skus.map((sku) => ({ ...sku, id: undefined })),
    address: {
      ...(payload.address && {
        ...payload.address,
        elevator: payload.address.elevator === 'true',
        location: undefined,
        latitude: payload.address.location?.lat,
        longitude: payload.address.location?.lng,
      }),
    },
    attachments: payload.attachments?.map((attachment) => {
      return {
        name: attachment.file_name ?? attachment.name,
        base64: (attachment.base64_file ?? attachment.base64)?.split(',')[1],
        type: attachment.file_type ?? attachment.type,
      };
    }),
  };

  try {
    const response = await api.post('/merchants/complete_onboarding', {
      data: formattedPayload,
    });

    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};
