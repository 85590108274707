import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useClipboard,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  getBillingAccountDetails,
  getBillings,
  requestBillingStatement,
} from 'api/Dashboard/settings';
import TablePage from 'components/Dashboard/TablePage';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import useTable from 'hooks/useTable';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as ReceiptListIcon } from 'assets/icons/receipt-list.svg';
import { ReactComponent as DollarSendIcon } from 'assets/icons/dollar-send-circle.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import SectionDataItem from 'components/Dashboard/SectionDataItem';
import { formatDate } from 'utils/date';
import { CheckCircleIcon, CheckIcon } from '@chakra-ui/icons';
import { createColumnHelper } from '@tanstack/react-table';
import StatusTag from 'components/Dashboard/StatusTag';
import VerifyBusiness from './VerifyBusiness';

const sirdabIBAN = 'SA17 8000 0610 6080 1231 8491';

const columnHelper = createColumnHelper();

const Billing = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onCopy, hasCopied } = useClipboard(sirdabIBAN);

  const {
    data: billingAccountDetails = {},
    isLoading,
    refetch,
  } = useQuery('billing-account-details', getBillingAccountDetails);

  const {
    mutate: requestBillingStatementMutation,
    isLoading: isRequestBillingStatementLoading,
  } = useMutation(requestBillingStatement, {
    onSuccess: (response) => {
      toast({
        title: response?.message,
        status: 'success',
        duration: 4000,
      });
    },
  });

  const {
    data: invoices = [],
    isLoading: isInvoicesLoading,
    pagination,
    onPaginationChange,
  } = useTable({
    fetch: getBillings,
    fetchKey: 'billings',
    disableURLParams: true,
  });

  if (isLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  const balanceAndAccountDetails = [
    {
      label: t('currentBalance'),
      value: `${billingAccountDetails.current_balance?.toLocaleString(
        'en'
      )} ${t('SAR')}`,
    },
    {
      label: t('signUpDate'),
      value:
        formatDate(billingAccountDetails.account_details?.sign_up_date) ?? '-',
    },
    {
      label: t('totalSpaceRented'),

      value: `${
        billingAccountDetails.account_details?.total_pallets_rented
      } ${t('SqM')} - ${
        billingAccountDetails.account_details?.total_space_rented
      } ${t('pallets')}`,
    },
  ];

  const estimatedAmount = billingAccountDetails.next_invoice?.estimated_amount;

  const nextInvoiceDetails = [
    {
      label: t('date'),
      value: formatDate(billingAccountDetails.next_invoice?.date) ?? '-',
    },
    {
      label: t('paymentCycle'),
      value: t(billingAccountDetails.next_invoice?.cycle),
    },
    {
      label: t('estimatedAmount'),
      value: estimatedAmount
        ? `${estimatedAmount?.toLocaleString('en')} ${t('SAR')}`
        : '-',
    },
  ];

  const bankDetails = [
    {
      label: t('iban'),
      value: (
        <Flex
          gap={2}
          alignItems="center"
          _rtl={{ flexDirection: 'row-reverse' }}
        >
          <Text as="span">{sirdabIBAN}</Text>

          <IconButton
            onClick={onCopy}
            size="xs"
            variant="unstyled"
            sx={{
              path: {
                fill: 'primary.500',
              },
            }}
            icon={
              hasCopied ? (
                <CheckIcon boxSize="20px" color="green.600" />
              ) : (
                <CopyIcon width="20" />
              )
            }
          />
        </Flex>
      ),
    },
    {
      label: t('bank'),
      value: 'Al Rajhi Bank',
    },
    {
      label: t('ownerName'),
      value: 'شركة سرداب للخدمات اللوجستية شركة شخص واحد',
    },
  ];

  const columns = [
    columnHelper.accessor('date_of_invoice', {
      cell: ({ getValue }) => (
        <Text fontWeight={500}>{formatDate(getValue(), 'd/M/yyyy')}</Text>
      ),
      header: t('date'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('description', {
      cell: ({ getValue }) => getValue(),
      header: t('description'),
    }),
    columnHelper.accessor('amount', {
      cell: ({ getValue }) => (
        <Center color="gray.800" fontWeight={600}>
          {getValue()?.toLocaleString('en')} {t('SAR')}
        </Center>
      ),
      header: t('amount'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('status', {
      cell: ({ getValue }) => {
        const value = getValue();
        return (
          <StatusTag
            colorScheme={
              value?.toLocaleLowerCase() === 'paid' ? 'green' : 'red'
            }
            size="sm"
          >
            {t(value)}
          </StatusTag>
        );
      },
      header: t('status'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('invoice_copy', {
      cell: ({ getValue }) => (
        <Center>
          <Button
            variant="outline"
            color="gray.600"
            textTransform="capitalize"
            fontWeight={500}
            size="sm"
            as="a"
            target="_blank"
            href={getValue()}
            download={getValue()}
            cursor="pointer"
          >
            {t('download')}
          </Button>
        </Center>
      ),
      header: t('actions'),
      meta: {
        isAction: true,
        centerHeader: true,
      },
    }),
  ];

  const isBusinessVerified = billingAccountDetails.verified;
  const cr_number = billingAccountDetails.cr_number;
  const vat_number = billingAccountDetails.vat_number;

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
      <GridItem colSpan={2}>
        <Card size="lg">
          <CardHeader>
            <SectionTitle title={t('crVatNumbers')} />
          </CardHeader>
          <CardBody>
            {isBusinessVerified ? (
              <Grid templateColumns="repeat(2, 1fr)" columnGap={16} rowGap={4}>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <SectionDataItem label={t('crNumber')} value={cr_number} />
                </GridItem>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <SectionDataItem label={t('vatNumber')} value={vat_number} />
                </GridItem>
                <GridItem colSpan={2}>
                  <Text color="green.500" fontWeight="500">
                    <CheckCircleIcon color="green.500" marginEnd={1} />
                    {t('verificationSuccess')}
                    <Button
                      onClick={() => window.Intercom('show')}
                      display="inline"
                      variant="link"
                      color="green.600"
                      marginStart={1}
                      textTransform="none"
                      fontWeight={700}
                    >
                      {t('customerSupport')}.
                    </Button>
                  </Text>
                </GridItem>
              </Grid>
            ) : (
              <VerifyBusiness
                onSuccess={() => refetch()}
                isVerified={isBusinessVerified}
                defaultValues={{
                  cr_number,
                  vat_number,
                }}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <Card size="lg">
          <CardHeader>
            <SectionTitle title={t('balanceAccountDetails')} />
          </CardHeader>
          <CardBody>
            <Stack spacing={4}>
              {balanceAndAccountDetails.map(({ label, value }, index) => (
                <SectionDataItem key={index} label={label} value={value} />
              ))}
            </Stack>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <Card size="lg" height="full">
          <CardHeader>
            <SectionTitle title={t('nextInvoice')} />
          </CardHeader>
          <CardBody>
            <Stack spacing={4}>
              {nextInvoiceDetails.map(({ label, value }, index) => (
                <SectionDataItem key={index} label={label} value={value} />
              ))}
            </Stack>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem colSpan={2}>
        <TablePage
          data={invoices}
          columns={columns}
          tableAction={
            <>
              <Flex gap={4} flexWrap="wrap">
                <Button
                  colorScheme="primary"
                  color="primary.500"
                  borderColor="primary.500"
                  variant="outline"
                  leftIcon={<DollarSendIcon />}
                  onClick={onOpen}
                >
                  {t('transferViaBankAction')}
                </Button>
                <Button
                  colorScheme="primary"
                  leftIcon={<ReceiptListIcon />}
                  onClick={requestBillingStatementMutation}
                  isLoading={isRequestBillingStatementLoading}
                >
                  {t('requestStatementAction')}
                </Button>
              </Flex>

              <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{t('transferViaBankAction')}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody fontSize="sm">
                    <Stack spacing={3} pb={4}>
                      {bankDetails.map(({ label, value }, index) => (
                        <SectionDataItem
                          key={index}
                          label={label}
                          value={value}
                        />
                      ))}
                    </Stack>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </>
          }
          title={<SectionTitle title={t('invoices')} hideDivider />}
          isLoading={isInvoicesLoading}
          enableSorting={false}
          pagination={pagination}
          onPaginationChange={onPaginationChange}
          hideSearch
        />
      </GridItem>
    </Grid>
  );
};

export default Billing;
