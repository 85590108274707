import {
  Button,
  Flex,
  FormLabel,
  Hide,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { ReactComponent as DocumentUploadIcon } from 'assets/icons/document-upload.svg';
import { createColumnHelper } from '@tanstack/react-table';
import StatusTag from 'components/Dashboard/StatusTag';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/Dashboard/ActionsButton';
import TablePage from 'components/Dashboard/TablePage';
import useTable from 'hooks/useTable';
import { AddIcon } from '@chakra-ui/icons';
import { getSKUs, importSKU } from 'api/Dashboard/skus';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import SKUTemplate from 'assets/bulk_sku_template.csv';
import { ReactComponent as SKUsEmptyTable } from 'assets/images/skus-empty-table.svg';
import EmptyTable from 'components/Dashboard/EmptyTable';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';

const columnHelper = createColumnHelper();

const CounterTag = ({ count, colorScheme }) => {
  if (isNaN(count)) {
    return null;
  }

  return (
    <>
      <Show below="md">{count}</Show>

      <Hide below="md">
        <StatusTag
          fontSize="sm"
          borderRadius="4px"
          colorScheme={colorScheme}
          color={`${colorScheme}.800`}
        >
          {count}
        </StatusTag>
      </Hide>
    </>
  );
};

const SKUs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    data,
    refetch,
    isLoading,
    selectedFilter,
    responseFilters,
    onFilterChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    onSearchChange,
  } = useTable({
    fetch: getSKUs,
    fetchKey: 'skus_list',
    searchKey: 'name_or_merchant_sku_or_system_sku_cont',
  });

  const { mutate: importSKUMutation, isLoading: isImportSKULoading } =
    useMutation(importSKU, {
      onSuccess: (success) => {
        refetch();
        toast({
          title: t('skuImportedSuccessfully'),
          status: 'success',
          duration: 2000,
        });
      },
      onError: (axiosError) => {
        const errorMessage = axiosError.response.data?.errors?.[0];

        toast({
          title: errorMessage,
          status: 'error',
          duration: 6000,
        });
      },
    });

  const filters = [
    {
      label: t('skuGroup'),
      name: 'sku_group_id',
      options: responseFilters.sku_groups?.map(([label, value]) => ({
        label,
        value,
      })),
      allLabel: 'groups',
    },
    {
      label: t('warehouse'),
      name: 'warehouse_id',
      options: responseFilters.warehouses?.map(([label, value]) => ({
        label: label,
        value: value,
      })),
      allLabel: t('warehouses'),
    },
  ];

  const booleanFilters = [
    {
      id: 'in_inventory',
      label: t('inInventory'),
    },
    {
      id: 'has_expiry',
      label: t('hasExpiry'),
    },
  ];

  const columns = [
    columnHelper.accessor('system_sku', {
      cell: ({ getValue }) => (
        <>
          <Show below="md">
            <Text
              color="gray.600"
              as="span"
              fontWeight={500}
              textTransform="capitalize"
            >
              {t('sirdabId')}
            </Text>
            {` #`}
          </Show>
          {getValue()}
        </>
      ),
      header: t('sirdabId'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('merchant_sku', {
      cell: ({ getValue }) => <Text fontWeight={500}>{getValue()}</Text>,
      header: t('merchantSKU'),
    }),
    columnHelper.accessor('name', {
      cell: ({ getValue }) => getValue(),
      header: t('name'),
    }),
    columnHelper.accessor('sku_groups_count', {
      cell: ({ getValue }) => getValue(),
      header: t('skuGroupCount'),
    }),
    columnHelper.accessor('on_hand.pallets', {
      cell: ({ getValue }) => (
        <CounterTag count={getValue()} colorScheme="yellow" />
      ),
      header: t('pallets'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('on_hand.boxes', {
      cell: ({ getValue }) => (
        <CounterTag count={getValue()} colorScheme="green" />
      ),
      header: t('boxes'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('on_hand.bins', {
      cell: ({ getValue }) => (
        <CounterTag count={getValue()} colorScheme="purple" />
      ),
      header: t('bins'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('on_hand.units', {
      cell: ({ getValue }) => (
        <CounterTag count={getValue()} colorScheme="purple" />
      ),
      header: t('units'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        return (
          <ActionsButton
            onClick={() => {
              navigate(`/skus/${row.original.id}`);
            }}
          >
            {t('manage')}
          </ActionsButton>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  const CreateSkuButton = ({ fullWidth }) => (
    <Button
      leftIcon={<AddIcon />}
      colorScheme="primary"
      textTransform="capitalize"
      size="lg"
      width={fullWidth ? 'full' : { base: 'full', md: 'auto' }}
      as={Link}
      to="create"
    >
      {t('skusPages.createNewSKU')}
    </Button>
  );

  const UploadSkuInput = () => (
    <Input
      type="file"
      id="importSKU"
      display="none"
      accept=".csv"
      onChange={(e) => {
        if (!e.target.files.length) {
          return;
        }
        importSKUMutation(e.target.files[0]);
        e.target.value = null;
      }}
    />
  );

  return (
    <TablePage
      data={data}
      columns={columns}
      filters={filters}
      booleanFilters={booleanFilters}
      selectedFilter={selectedFilter}
      pagination={pagination}
      title={t('skusPages.skusList')}
      pageAction={
        <Flex gap={4} flexFlow={{ base: 'column-reverse', md: 'row' }}>
          <Menu>
            <MenuButton
              as={Button}
              colorScheme="gray"
              textTransform="capitalize"
              size="lg"
              bgColor="white"
              color="gray.700"
              width={{ base: 'full', md: 'auto' }}
              leftIcon={<DocumentUploadIcon />}
              isLoading={isImportSKULoading}
            >
              {t('skusPages.importNewSKU')}
            </MenuButton>
            <MenuList>
              <MenuItem
                as={FormLabel}
                htmlFor="importSKU"
                textTransform="capitalize"
                cursor="pointer"
              >
                {t('skusPages.uploadSKUlist')}
                <UploadSkuInput />
              </MenuItem>
              <MenuItem as="a" href={SKUTemplate} download="sku_template">
                {t('skusPages.downloadSKUTemplate')}
              </MenuItem>
            </MenuList>
          </Menu>

          <CreateSkuButton />
        </Flex>
      }
      onFilterChange={onFilterChange}
      onSortingChange={onSortingChange}
      onPaginationChange={onPaginationChange}
      searchPlaceholder={t('skusPages.searchPlaceholder')}
      onSearchChange={onSearchChange}
      isLoading={isLoading}
      emptyTable={
        <EmptyTable
          illustration={<SKUsEmptyTable />}
          title={t('noSKUsYet')}
          subtitle={t('createSingleOrBulkUploadSKUs')}
          actions={
            <Stack width="full" spacing={4}>
              <CreateSkuButton fullWidth />
              <SimpleGrid columns={{ base: 1, md: 2 }} gap={4}>
                <Button
                  leftIcon={<DownloadIcon width="20px" height="20px" />}
                  colorScheme="primary"
                  variant="outline"
                  size="lg"
                  as="a"
                  href={SKUTemplate}
                  download="sku_template"
                  width="full"
                >
                  {t('skusPages.downloadSKUTemplate')}
                </Button>

                <Button
                  leftIcon={<UploadIcon width="20px" height="20px" />}
                  colorScheme="primary"
                  variant="outline"
                  size="lg"
                  as={FormLabel}
                  htmlFor="importSKU"
                  cursor="pointer"
                  width="full"
                >
                  {t('skusPages.uploadSKUlist')}
                  <UploadSkuInput />
                </Button>
              </SimpleGrid>
            </Stack>
          }
        />
      }
    />
  );
};

export default SKUs;
