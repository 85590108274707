import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';

const Tooltip = ({ label, icon, children, ...props }) => {
  return (
    <Popover trigger="hover" variant="responsive" {...props}>
      <PopoverTrigger>
        {children ? (
          children
        ) : (
          <IconButton
            variant="unstyled"
            minW="none"
            minHeight="auto"
            height="auto"
            aria-label="more info"
            icon={icon}
          />
        )}
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          style={{ whiteSpace: 'normal', wordBreak: 'breakWord' }}
          bg="gray.900"
          color="white"
          border="none"
        >
          <PopoverArrow bg="gray.900" border="none" boxShadow="none" />
          <PopoverBody>{label}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default Tooltip;
