import api from 'utils/axios';
import { getToken } from 'utils/token';

export const signUp = async (user) => {
  try {
    const response = await api.post('/users/sign_up', user);
    const { data, headers } = response;

    return { data, headers, payload: user };
  } catch (error) {
    throw error;
  }
};

export const signIn = async (user) => {
  try {
    const response = await api.post('/users/sign_in', user);
    const { data, headers } = response;

    return { data, headers };
  } catch (error) {
    throw error;
  }
};

export const refreshToken = async () => {
  try {
    const requestHeaders = {
      'Refresh-Token': getToken('token').refreshToken,
    };

    const { data, headers } = await api.post(
      '/users/tokens',
      {},
      {
        headers: requestHeaders,
      }
    );

    return { data, headers };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const signOut = async () => {
  try {
    const response = await api.delete('/users/sign_out');

    if (response.status === 200) {
      console.log('Sign-out successful');
    } else {
      console.error('Sign-out failed with status code', response.status);
    }
  } catch (error) {
    console.error('Sign-out failed', error);
  }
};

export const forgetPassword = async (user) => {
  try {
    const { data } = await api.post('/users/password', user);

    return { data };
  } catch (error) {
    throw error;
  }
};

export const setPassword = async (user) => {
  try {
    const { data, headers } = await api.patch('/users/password', user);

    return { data, headers };
  } catch (error) {
    throw error;
  }
};

export const resendOTP = async () => {
  try {
    const response = await api('/users/resend_otp');
    const { data } = response;

    return { data };
  } catch (error) {
    throw error;
  }
};

export const confirmOTP = async (otp) => {
  try {
    const response = await api.put('/users/confirm_otp', { otp_code: otp });
    const { data } = response;

    return { data };
  } catch (error) {
    throw error;
  }
};

export const getUser = async () => {
  try {
    const response = await api('/users/me');
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateLead = async (lead) => {
  try {
    const response = await api.put('/users/update_lead', { lead });
    const { data } = response;

    return { data };
  } catch (error) {
    throw error;
  }
};

export const updateCurrentUser = async (user) => {
  try {
    const response = await api.put('/users/update_user', { user });
    const { data } = response;

    return { data };
  } catch (error) {
    throw error;
  }
};

export const createMagicLink = async (email) => {
  try {
    const { data } = await api.post('/users/magic_link', email);

    return { data };
  } catch (error) {
    throw error;
  }
};

export const passwordlessLogin = async (token) => {
  try {
    const response = await api.post('/users/passwordless_login', token);
    const { data, headers } = response;

    return { data, headers };
  } catch (error) {
    throw error;
  }
};

export const getOrganization = async (id) => {
  try {
    const response = await api(`/users/${id}/organization`);
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};
