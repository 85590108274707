import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, useToast } from '@chakra-ui/react';
import BusinessNeeds from 'components/Onboarding/BusinessNeeds/BusinessNeeds';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import {
  getOnboardingState,
  updateOnboardingState as updateOnboardingStateAPI,
  verifyBusiness,
} from 'api/onboarding';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';
import VerifyBusiness from 'components/Onboarding/VerifyBusiness';
import ModalWizard from 'components/shared/ModalWizard';
import OnboardingNavbar from 'components/Navbar/OnboardingNavbar';

const updateUserOnboardingFinished = () => {
  const user = getLocalStorage('user');
  setLocalStorage('user', { ...user, onboarding_finished: true });
};

const Onboarding = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  const [onboardingState, setOnboardingState] = useState({});

  const { data: onboardingData, isLoading: isOnboardingDataLoading } = useQuery(
    'onboarding-data',
    getOnboardingState,
    {
      onSuccess: (data) => {
        if (!isNaN(data.state.activeStep)) {
          const state = data.state;
          setOnboardingState(state);
        } else {
          setOnboardingState({ activeStep: 0 });
        }
      },
    }
  );

  const {
    mutate: updateOnboardingState,
    isLoading: isUpdateOnboardingStateLoading,
    data: updateOnboardingStateData,
  } = useMutation(updateOnboardingStateAPI, {
    onSuccess: (data) => {
      setOnboardingState(data.state);
    },
  });

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const paramsStepNextValue =
      (!isNaN(onboardingState.activeStep) ? onboardingState.activeStep : 0) + 1;
    const paramsStep = +searchParams.get('step');

    if (!onboardingData || paramsStepNextValue === paramsStep) {
      return;
    }

    setSearchParams({ step: paramsStepNextValue });
  }, [
    onboardingState.activeStep,
    setSearchParams,
    onboardingData,
    searchParams,
  ]);

  const handleUpdateOnboardingState = (
    state,
    { onSuccess, isCompleted } = {}
  ) => {
    updateOnboardingState(
      {
        onboardingStateId: onboardingData.id,
        onboarding: {
          state,
          complete: isCompleted,
        },
      },
      {
        onSuccess,
      }
    );
  };

  const {
    mutate: verifyBusinessMutation,
    isLoading: isVerifyBusinessLoading,
    error: verifyBusinessError,
  } = useMutation(verifyBusiness, {
    onSuccess: (data) => {
      onVerifyBusinessChange('isVerified', true);
    },
  });

  const handlePrevButtonClick = (onSuccess) => {
    handleUpdateOnboardingState(
      {
        ...onboardingState,
        activeStep: onboardingState.activeStep - 1,
      },
      { onSuccess }
    );
  };

  const handleNextButtonClick = () => {
    if (onboardingState.activeStep === steps.length - 1) {
      handleOnboardingComplete();
      return;
    }

    handleUpdateOnboardingState({
      ...onboardingState,
      activeStep: onboardingState.activeStep + 1,
    });
  };

  const onBusinessNeedsChange = (name, value) => {
    const onboardingStateMock = { ...onboardingState };

    handleUpdateOnboardingState({
      ...onboardingStateMock,
      businessNeeds: {
        ...onboardingStateMock.businessNeeds,
        [name]: value,
      },
    });
  };

  const onBusinessNeedsSubmit = (data) => {
    handleUpdateOnboardingState({
      ...onboardingState,
      businessNeeds: data,
      activeStep: onboardingState.activeStep + 1,
    });
  };

  const onVerifyBusinessChange = (name, value) => {
    const isVerified = name === 'isVerified' && value === true;

    handleUpdateOnboardingState(
      {
        ...onboardingState,
        businessVerification: {
          ...onboardingState.businessVerification,
          isVerified: false,
          [name]: value,
        },
      },
      {
        onSuccess: isVerified ? handleOnboardingComplete : undefined,
      }
    );
  };

  const onVerifyBusinessSubmit = (data) => {
    verifyBusinessMutation(data);
  };

  const handleOnboardingComplete = () => {
    handleUpdateOnboardingState(
      { ...onboardingState },
      {
        isCompleted: true,
        onSuccess: () => {
          updateUserOnboardingFinished();
          navigate('/warehouses');
          toast({
            title:
              'Congratulations! You can search for warehouses and request a quote on this page.',
            status: 'success',
            duration: 6000,
          });
        },
      }
    );
  };

  const steps = [
    {
      title: t('tellUsMoreAboutYou'),
      subtitle: t('helpUsLearnMoreAboutYourStorageNeeds'),
      content: isOnboardingDataLoading ? (
        <Spinner color="primary.500" size="lg" />
      ) : (
        <BusinessNeeds
          onChange={onBusinessNeedsChange}
          onSubmit={onBusinessNeedsSubmit}
          defaultValues={
            updateOnboardingStateData?.state.businessNeeds ??
            onboardingData?.state.businessNeeds ??
            {}
          }
          disabled={isUpdateOnboardingStateLoading}
        />
      ),
      nextButtonLabel: t('next'),
      nextButtonFormId: 'BusinessNeedsForm',
      nextButtonType: 'submit',
    },
    {
      title: t('verifyYourBusiness'),
      subtitle: t('helpUsSpeedUpTheProcess'),
      content: (
        <VerifyBusiness
          onChange={onVerifyBusinessChange}
          onSubmit={onVerifyBusinessSubmit}
          defaultValues={onboardingState.businessVerification ?? {}}
          error={verifyBusinessError?.response.data.errors[0]}
          isVerified={onboardingState.businessVerification?.isVerified}
        />
      ),
      nextButtonLabel: t('submit'),
      nextButtonFormId: 'VerifyBusinessForm',
      nextButtonType: 'submit',
      prevButtonLabel: t('back'),
      isNextButtonLoading: isVerifyBusinessLoading,
      containerSize: 'sm',
      canSkip: true,
    },
  ];

  if (isOnboardingDataLoading) {
    return null;
  }

  return (
    <>
      <OnboardingNavbar />
      <ModalWizard
        steps={steps}
        activeStep={onboardingState.activeStep ?? 0}
        handlePrevButtonClick={handlePrevButtonClick}
        handleNextButtonClick={handleNextButtonClick}
        isLoading={isUpdateOnboardingStateLoading}
        isOpen
      />
    </>
  );
};

export default Onboarding;
