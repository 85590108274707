import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { getQuote, approveQuote } from 'api/onboarding';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import Container from 'components/shared/Container';
import Quote from 'components/Onboarding/Quote/Quote';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { saveToken, getToken } from 'utils/token';
import { quoteLineItemsToQuote } from 'utils/quote';

const Lead = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();
  const decodedURL = useMemo(
    () => decodeURIComponent(location.search),
    [location.search]
  );

  const searchParams = useMemo(
    () => new URLSearchParams(decodedURL),
    [decodedURL]
  );

  const token = useMemo(() => searchParams.get('token'), [searchParams]);
  const quoteId = searchParams.get('quoteId');

  const [quoteState, setQuoteState] = useState({});

  useEffect(() => {
    const savedToken = getToken();

    if (savedToken.token === token) {
      return;
    }
    saveToken({
      newToken: token,
    });
  }, [token]);

  const { data, isLoading } = useQuery(
    ['lead-quote', quoteId],
    () => getQuote(quoteId),
    {
      onSuccess: (data) => {
        setQuoteState((prevState) => ({
          ...prevState,
          quote: quoteLineItemsToQuote(data.quote_line_items),
          fees: data.total / 100,
        }));
      },
    }
  );

  const isQuoteApproved = data?.active;

  const quoteLineItems = data?.quote_line_items;
  const quoteDefaultValue = quoteLineItemsToQuote(quoteLineItems);
  const services = quoteLineItems?.map((quoteLineItem) => ({
    ...quoteLineItem.warehouse_service,
    price_per_unit_cents: quoteLineItem.price_per_unit_cents,
  }));

  const { mutate: approveQuoteMutation, isLoading: isApproveQuoteLoading } =
    useMutation(approveQuote, {
      onSuccess: (data) => {
        navigate('/sign-up', { state: { quoteId } });
      },
    });

  const handleApproveQuote = () => {
    const newQuoteLineItems = Object.keys(quoteState.quote).map((key) => {
      const value = +quoteState.quote[key];
      const serviceId = +key.split('-')[1];

      const id = quoteLineItems.find(
        (item) => item.warehouse_service_id === serviceId
      ).id;

      return {
        id,
        quantity: value,
      };
    });

    approveQuoteMutation({
      quoteId,
      quoteLineItems: newQuoteLineItems,
    });
  };

  const onQuoteChange = (name, value) => {
    setQuoteState((prevState) => ({
      ...prevState,
      fees: undefined,
    }));
  };

  const handleFeesUpdate = (newFees, quote) => {
    setQuoteState((prevState) => ({
      fees: newFees,
      quote,
    }));
  };

  return (
    <Flex width="full" flex={1} justifyContent="center" bg="#F8FAFC" pt={10}>
      <Container>
        <Stack spacing={3} textAlign="center">
          <Heading fontWeight="medium" fontSize="3xl">
            {t('reviewAndApproveQuote')}
          </Heading>
          <Text fontSize="sm" color="gray.500">
            {t('customizeYourSelectedQuota')}
          </Text>
        </Stack>
        <Center paddingBlock={8}>
          <Box
            bg="white"
            p={4}
            borderRadius="3xl"
            width="container.md"
            borderWidth={1}
          >
            <Quote
              onChange={onQuoteChange}
              setFees={handleFeesUpdate}
              fees={quoteState.fees}
              defaultValues={quoteDefaultValue ?? {}}
              services={services}
              disabled={isApproveQuoteLoading || isQuoteApproved}
              disableSubmit={!isNaN(quoteState.fees) || isQuoteApproved}
              isLoading={isLoading}
              ignorePriceRange
            />
            {!isNaN(quoteState.fees) && (
              <Button
                colorScheme="primary"
                w="full"
                size="lg"
                marginTop="4"
                onClick={handleApproveQuote}
                isDisabled={isApproveQuoteLoading || isQuoteApproved}
              >
                {t('approveQuote')}
              </Button>
            )}
            {isQuoteApproved && (
              <Text color="gray.600" fontWeight={600} mt={4}>
                <Trans
                  i18nKey="yourQuoteIsAlreadyApproved"
                  components={[
                    <Link as={RouterLink} to="/sign-up" color="primary.500" />,
                  ]}
                />
              </Text>
            )}
          </Box>
        </Center>
      </Container>
    </Flex>
  );
};

export default Lead;
