import api from 'utils/axios';

export const getWarehouses = async () => {
  try {
    const response = await api('/merchants/warehouses');
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getWarehouseById = async (id) => {
  try {
    const response = await api(`/merchants/warehouses/${id}`);
    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const getWarehouseTimeSlots = async ({ address_id, selected_date }) => {
  try {
    const response = await api('/merchants/warehouses/time_slots', {
      params: { address_id, selected_date },
    });
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getQuotations = async () => {
  try {
    const response = await api('/merchants/quotes');
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};
