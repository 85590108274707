import { Spinner, useToast } from '@chakra-ui/react';
import { getSKUGroupById, updateSKUGroup } from 'api/Dashboard/skuGroups';
import SKUGroupsFormPage from 'components/Dashboard/SKUGroups/SKUGroupsFormPage';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const SKUGroupsEdit = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  const { id } = useParams();

  const {
    data: skuGroup = {},
    isFetching,
    isLoading,
  } = useQuery(['sku-group', id], () => getSKUGroupById(id), {
    cacheTime: 100,
    refetchOnMount: true,
  });

  const { mutate: updateSKUGroupMutation, isLoading: isUpdateSKULoading } =
    useMutation((payload) => updateSKUGroup(id, payload), {
      onSuccess: (response) => {
        toast({
          title: t('skuGroupUpdatedSuccessfully'),
          status: 'success',
          duration: 5000,
        });

        navigate(`/sku-groups`);
      },
    });

  if (isLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  return (
    <SKUGroupsFormPage
      onSubmit={updateSKUGroupMutation}
      skuGroupId={id}
      defaultValues={skuGroup}
      isLoading={isUpdateSKULoading}
      isFetching={isFetching}
    />
  );
};

export default SKUGroupsEdit;
