import api from 'utils/axios';

export const getFeatureFlags = async () => {
  try {
    const response = await api('/feature_flags');
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};
