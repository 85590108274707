import { useTranslation } from 'react-i18next';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import CollapsibleCard from '../CollapsibleCard';
import {
  Box,
  Center,
  Divider,
  Flex,
  IconButton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Td,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import Table from '../Table';
import { createColumnHelper } from '@tanstack/react-table';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  getChargeableActivitiesSummary as getInboundChargeableActivitiesSummary,
  getChargeableActivitiesByInbound,
  getChargeableActivitiesBySKU as getInboundChargeableActivitiesBySKU,
} from 'api/Dashboard/inbounds';

import {
  getChargeableActivitiesSummary as getOutboundChargeableActivitiesSummary,
  getChargeableActivitiesByOutbound,
  getChargeableActivitiesBySKU as getOutboundChargeableActivitiesBySKU,
} from 'api/Dashboard/outbounds';

import useTable from 'hooks/useTable';
import i18n from 'utils/i18n';

const columnHelper = createColumnHelper();

const showPriceInSAR = (price) => `${price ?? 0} ${i18n.t('SAR')}`;

const ChargeableActivitiesSummary = ({ orderId, isOutbound }) => {
  const { t } = useTranslation();

  const {
    data: chargeableActivitiesSummary = [],
    isLoading,
    onPaginationChange,
    pagination,
  } = useTable({
    fetch: (searchParams) =>
      isOutbound
        ? getOutboundChargeableActivitiesSummary(orderId, searchParams)
        : getInboundChargeableActivitiesSummary(orderId, searchParams),
    fetchKey: ['inbound-chargeable-activities-summary', orderId],
    disableURLParams: true,
  });

  const columns = [
    columnHelper.accessor('service_name', {
      cell: ({ getValue }) => <Text fontWeight={600}>{getValue()}</Text>,
      header: t('serviceName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('service_unit_type', {
      cell: ({ getValue }) => <Center fontWeight={500}>{t(getValue())}</Center>,
      header: t('unit'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('price_per_unit', {
      cell: ({ getValue }) => (
        <Center fontWeight={500}>
          {getValue()}
          <Text as="span" fontWeight={600} marginInlineStart={1}>
            {t('SAR')}
          </Text>
        </Center>
      ),
      header: `${t('rate')} (${t('SAR')})`,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('quantity', {
      cell: ({ getValue }) => (
        <SKUActivityText center>{getValue()}</SKUActivityText>
      ),
      header: t('quantity'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('total_price', {
      cell: ({ getValue }) => (
        <Center fontWeight={500}>
          {getValue()}
          <Text as="span" fontWeight={600} marginInlineStart={1}>
            {t('SAR')}
          </Text>
        </Center>
      ),
      header: `${t('total')} (${t('SAR')})`,
      meta: {
        centerHeader: true,
      },
    }),
  ];

  return (
    <>
      <Table
        data={chargeableActivitiesSummary.chargeable_activities ?? []}
        columns={columns}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        pageCount={pagination.totalPages}
        enableSorting={false}
        isLoading={isLoading}
      />

      <Flex justifyContent="flex-end">
        <Stack
          spacing={3}
          backgroundColor="primary.50"
          padding={6}
          borderRadius="xl"
          flex={1}
          maxW={{ base: '100%', md: '525px' }}
        >
          <Text
            color="primary.600"
            fontSize="xl"
            fontWeight={600}
            textTransform="capitalize"
          >
            {t('billingSummary')}
          </Text>

          <Stack>
            <Flex
              justifyContent="space-between"
              fontWeight={500}
              color="gray.700"
              fontSize="sm"
            >
              <Text textTransform="capitalize">{t('grossTotal')}</Text>
              <Text>
                {showPriceInSAR(
                  chargeableActivitiesSummary.total_chargeable_activities
                )}
              </Text>
            </Flex>

            <Flex
              justifyContent="space-between"
              fontWeight={500}
              color="gray.700"
              fontSize="sm"
            >
              <Text textTransform="capitalize">{`${t('VAT')} (15%)`}</Text>
              <Text>
                {showPriceInSAR(chargeableActivitiesSummary.total_vat)}
              </Text>
            </Flex>

            <Divider borderColor="gray.300" />

            <Flex
              justifyContent="space-between"
              fontWeight={700}
              color="primary.700"
            >
              <Text textTransform="capitalize">{t('total')}</Text>
              <Text>
                {showPriceInSAR(
                  chargeableActivitiesSummary.total_chargeable_activities_with_vat
                )}
              </Text>
            </Flex>
          </Stack>
        </Stack>
      </Flex>
    </>
  );
};

const ChargeableActivitiesByInbound = ({ orderId, isOutbound }) => {
  const { t } = useTranslation();

  const {
    data: chargeableActivitiesByInbound = [],
    isLoading,
    onPaginationChange,
    pagination,
  } = useTable({
    fetch: (searchParams) =>
      isOutbound
        ? getChargeableActivitiesByOutbound(orderId, searchParams)
        : getChargeableActivitiesByInbound(orderId, searchParams),
    fetchKey: ['inbound-chargeable-activities-by-inbound', orderId],
    disableURLParams: true,
  });

  const columns = [
    columnHelper.accessor('service_name', {
      cell: ({ getValue }) => <Text fontWeight={600}>{getValue()}</Text>,
      header: t('serviceName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('service_unit_type', {
      cell: ({ getValue }) => <Center fontWeight={500}>{t(getValue())}</Center>,
      header: t('unit'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('price_per_unit', {
      cell: ({ getValue }) => (
        <Center fontWeight={500}>
          {getValue()}
          <Text as="span" fontWeight={600} marginInlineStart={1}>
            {t('SAR')}
          </Text>
        </Center>
      ),
      header: `${t('rate')} (${t('SAR')})`,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('quantity', {
      cell: ({ getValue }) => (
        <SKUActivityText center>{getValue()}</SKUActivityText>
      ),
      header: t('quantity'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('total_price', {
      cell: ({ getValue }) => (
        <Center fontWeight={500}>
          {getValue()}
          <Text as="span" fontWeight={600} marginInlineStart={1}>
            {t('SAR')}
          </Text>
        </Center>
      ),
      header: `${t('total')} (${t('SAR')})`,
      meta: {
        centerHeader: true,
      },
    }),
  ];

  return (
    <Table
      data={chargeableActivitiesByInbound}
      columns={columns}
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      pageCount={pagination.totalPages}
      enableSorting={false}
      isLoading={isLoading}
    />
  );
};

const SKUActivityText = ({ children, center }) => (
  <Text
    fontWeight={{ base: 400, md: 600 }}
    color={{ base: 'gray.900', md: 'gray.600' }}
    textAlign={center ? 'center' : undefined}
  >
    {children}
  </Text>
);

const ChargeableActivitiesBySKUs = ({ orderId, isOutbound }) => {
  const { t } = useTranslation();

  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  const {
    data: chargeableActivitiesBySKU = [],
    isLoading,
    onPaginationChange,
    pagination,
  } = useTable({
    fetch: (searchParams) =>
      isOutbound
        ? getOutboundChargeableActivitiesBySKU(orderId, searchParams)
        : getInboundChargeableActivitiesBySKU(orderId, searchParams),
    fetchKey: ['inbound-chargeable-activities-by-sku', orderId],
    disableURLParams: true,
  });

  const columns = [
    {
      id: 'expander',
      header: null,
      cell: ({ row }) => {
        return row.getCanExpand() ? (
          <IconButton
            variant="unstyled"
            onClick={row.getToggleExpandedHandler()}
            icon={
              row?.getIsExpanded() ? (
                <ChevronUpIcon boxSize="24px" />
              ) : (
                <ChevronDownIcon boxSize="24px" />
              )
            }
          />
        ) : null;
      },
      meta: { fitContent: true, isAction: true },
    },
    columnHelper.accessor('sku_name', {
      cell: ({ getValue }) => <Text fontWeight={500}>{getValue()}</Text>,
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
        fitContent: true,
      },
    }),
    columnHelper.accessor('sku_system_sku', {
      cell: ({ getValue }) => <Text fontWeight={500}>{getValue()}</Text>,
      header: t('sirdabSKU'),
    }),
    columnHelper.accessor('storage', {
      cell: ({ getValue }) => (
        <Text fontWeight={500}>{t(getValue()?.unit)}</Text>
      ),
      header: t('UOM'),
    }),
    columnHelper.accessor('cases_pallets', {
      cell: ({ getValue }) => <Center fontWeight={500}>{getValue()}</Center>,
      header: t('casesPallets'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('units_cases', {
      cell: ({ getValue }) => <Center fontWeight={500}>{getValue()}</Center>,
      header: t('unitsCase'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('sum_of_chargeable_activities', {
      cell: ({ getValue }) => (
        <Center fontWeight={600}>{showPriceInSAR(getValue())}</Center>
      ),
      header: `${t('total')} (${t('SAR')})`,
      meta: {
        centerHeader: true,
      },
    }),
  ];

  const chargeableActivitiesColumns = [
    columnHelper.accessor('service_name', {
      cell: ({ getValue }) => <SKUActivityText>{getValue()}</SKUActivityText>,
      header: t('serviceName'),
      meta: {
        hideHash: true,
      },
    }),
    columnHelper.accessor('service_unit_type', {
      cell: ({ getValue }) => (
        <SKUActivityText>{t(getValue())}</SKUActivityText>
      ),
      header: t('unit'),
    }),
    columnHelper.accessor('price_per_unit', {
      cell: ({ getValue }) => (
        <SKUActivityText center>{getValue()}</SKUActivityText>
      ),
      header: `${t('rate')} (${t('SAR')})`,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('quantity', {
      cell: ({ getValue }) => (
        <SKUActivityText center>{getValue()}</SKUActivityText>
      ),
      header: t('quantity'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('total_price', {
      cell: ({ getValue }) => (
        <Center fontWeight={600}>{`${getValue()} ${t('SAR')}`}</Center>
      ),
      header: `${t('total')} (${t('SAR')})`,
      meta: {
        centerHeader: true,
      },
    }),
  ];

  return (
    <Table
      data={chargeableActivitiesBySKU}
      columns={columns}
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      pageCount={pagination.totalPages}
      enableSorting={false}
      getRowCanExpand={() => true}
      isLoading={isLoading}
      renderSubComponent={({ row }) => (
        <>
          <Td></Td>
          <Td
            colSpan={7}
            display={isMobile ? 'block !important' : 'table-cell'}
          >
            <Box
              style={{
                borderWidth: isMobile ? 0 : '1px',
                borderColor: 'gray.300',
                borderBottom: 0,

                overflow: 'hidden',
                padding: isMobile ? '0 16px' : 0,
              }}
              backgroundColor={isMobile ? 'gray.50' : 'white'}
              borderRadius="xl"
            >
              <Table
                data={row.original.chargeable_activities ?? []}
                columns={chargeableActivitiesColumns}
                hidePagination
                enableSorting={false}
              />
            </Box>
          </Td>
        </>
      )}
    />
  );
};

const ActivityTab = ({ children }) => (
  <Tab
    borderRadius="lg"
    fontSize="sm"
    fontWeight={600}
    _selected={{ color: 'primary.500', bg: 'primary.100' }}
    color="gray.600"
    textTransform="capitalize"
  >
    {children}
  </Tab>
);

const ChargeableActivities = ({ orderId, isOutbound }) => {
  const { t } = useTranslation();

  return (
    <CollapsibleCard
      title={
        <SectionTitle title={t('chargeableActivitiesSummary')} hideDivider />
      }
      body={
        <Tabs variant="unstyled" isLazy>
          <TabList gap={2} paddingBottom={{ base: 0, md: 4 }}>
            <ActivityTab>{t('summary')}</ActivityTab>
            <ActivityTab>
              {t(isOutbound ? 'byOutbound' : 'byInbound')}
            </ActivityTab>
            <ActivityTab>{t('bySKU')}</ActivityTab>
          </TabList>
          <TabPanels overflowY="auto">
            <TabPanel padding={0}>
              <ChargeableActivitiesSummary
                orderId={orderId}
                isOutbound={isOutbound}
              />
            </TabPanel>
            <TabPanel padding={0}>
              <ChargeableActivitiesByInbound
                orderId={orderId}
                isOutbound={isOutbound}
              />
            </TabPanel>
            <TabPanel padding={0}>
              <ChargeableActivitiesBySKUs
                orderId={orderId}
                isOutbound={isOutbound}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      }
      isOpen
    />
  );
};

export default ChargeableActivities;
