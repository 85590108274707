import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
} from '@chakra-ui/react';
import useLanguage from 'hooks/useLanguage';
import { useTranslation } from 'react-i18next';
import TitleDivider from './TitleDivider';

const PageDrawer = ({
  children,
  title,
  isOpen,
  onClose,
  formId,
  isLoading,
  size = 'lg',
  submitText = 'save',
  onSubmit,
  cancelText = 'cancel',
  isDangerous,
  onDecline,
  hideSubmitButton,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  return (
    <Drawer
      isOpen={isOpen}
      placement={language === 'ar' ? 'left' : 'right'}
      onClose={onClose}
      size={size}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton
          size="lg"
          top={7}
          _rtl={{
            right: 'auto',
            left: 3,
          }}
        />
        <DrawerHeader
          display="flex"
          alignItems="center"
          gap={2}
          borderBottom="1px"
          borderColor="gray.200"
          textTransform="capitalize"
          paddingInline={6}
          paddingBlock={8}
        >
          <TitleDivider />
          {title}
        </DrawerHeader>

        <DrawerBody paddingTop={7}>{children}</DrawerBody>

        <DrawerFooter gap={3}>
          <Button
            size="lg"
            variant="outline"
            onClick={onDecline ?? onClose}
            isDisabled={isLoading}
            color={isDangerous ? 'red.500' : undefined}
          >
            {t(cancelText)}
          </Button>
          {!hideSubmitButton && (
            <Button
              textTransform="capitalize"
              size="lg"
              colorScheme="primary"
              form={formId}
              type="submit"
              loadingText={t(submitText)}
              isLoading={isLoading}
              onClick={onSubmit}
            >
              {t(submitText)}
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default PageDrawer;
