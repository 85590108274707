import {
  Button,
  Heading,
  Text,
  Stack,
  Spinner,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ModalWizard = ({
  steps,
  activeStep,
  handlePrevButtonClick,
  handleNextButtonClick,
  isLoading,
  isOpen,
}) => {
  const { t } = useTranslation();

  const currentStep = steps[activeStep];

  if (!currentStep) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} size="3xl" isCentered>
      <ModalOverlay />
      <ModalContent gap={6} borderRadius="3xl">
        <ModalHeader padding={6} borderBottomWidth={1}>
          <Stack spacing={3} textAlign="center">
            <Heading fontWeight="bold" fontSize="2xl">
              {currentStep.title}
            </Heading>
            <Text fontSize="sm" color="gray.600" fontWeight={500}>
              {currentStep.subtitle}
            </Text>
          </Stack>
        </ModalHeader>

        <ModalBody
          paddingY={0}
          maxHeight={{ base: '50vh', md: '40vh' }}
          overflowY="auto"
        >
          {currentStep.content}
        </ModalBody>

        <ModalFooter
          padding={6}
          gap={4}
          justifyContent="flex-end"
          alignItems="center"
          borderTopWidth={1}
        >
          {isLoading && <Spinner color="primary.500" size="md" />}
          {currentStep.canSkip && (
            <Button
              onClick={() => handleNextButtonClick()}
              variant="secondary"
              minWidth="150"
              border="none"
              colorScheme="gray.600"
              size="lg"
            >
              {t('skipForNow')}
            </Button>
          )}
          {activeStep > 0 && !currentStep.hidePrevButton && (
            <Button
              onClick={() => handlePrevButtonClick()}
              variant="secondary"
              minWidth="150"
              size="lg"
            >
              {currentStep.prevButtonLabel}
            </Button>
          )}
          {!currentStep.hideNextButton && (
            <Button
              onClick={
                currentStep.nextButtonType === 'submit'
                  ? undefined
                  : () => handleNextButtonClick()
              }
              type={currentStep.nextButtonType}
              form={currentStep.nextButtonFormId}
              colorScheme="primary"
              minWidth="150"
              isLoading={currentStep.isNextButtonLoading}
              isDisabled={currentStep.isNextButtonDisabled}
              size="lg"
            >
              {currentStep.nextButtonLabel}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalWizard;
