import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const useTable = ({
  fetch,
  fetchKey,
  pageFilterKey,
  searchKey,
  disableURLParams,
  defaultState = {},
  onFetchSuccess,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const [responseFilters, setResponseFilters] = useState([]);

  const [tableState, setTableState] = useState({
    filter: undefined,
    sorting: defaultState.sorting ?? [],
    pagination: { pageIndex: 0, pageSize: 5, totalPages: 1 },
    search: '',
  });

  const { filter, sorting, pagination, search } = tableState;

  const {
    data = {},
    isLoading,
    refetch,
  } = useQuery(
    [
      fetchKey,
      pagination.pageIndex,
      pagination.pageSize,
      filter,
      sorting[0],
      search,
    ],
    () => {
      const sort = sorting[0];

      const queryParams = {
        page: pagination.pageIndex + 1,
        per_page: pagination.pageSize,
        ...filter,
        ...(sort ? { 'q[s]': `${sort.id} ${sort.desc ? 'desc' : 'asc'}` } : {}),
        ...(search ? { [`q[${searchKey}]`]: search } : {}),
      };

      const urlSearchParams = new URLSearchParams(queryParams);

      const selectedPageFilters = filter?.[pageFilterKey];

      if (selectedPageFilters?.length) {
        urlSearchParams.delete(pageFilterKey);

        selectedPageFilters?.forEach((pageFilterValue) => {
          urlSearchParams.append(pageFilterKey, pageFilterValue);
        });
      }

      const queryString = urlSearchParams.toString();

      if (!disableURLParams) {
        setSearchParams(queryString, {
          replace: true,
        });
      }

      return fetch(queryString);
    },
    {
      onSuccess: ({ meta, data }) => {
        setTableState((prevState) => ({
          ...prevState,
          pagination: {
            ...prevState.pagination,
            totalPages: meta.pagination.total_pages,
          },
        }));

        if (!responseFilters.length && meta.select_options) {
          setResponseFilters(meta.select_options);
        }

        onFetchSuccess?.(data);
      },
      cacheTime: 500,
      refetchOnMount: true,
    }
  );

  const onFilterChange = (newFilter) => {
    setTableState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        ...newFilter,
      },
      pagination: {
        ...prevState.pagination,
        pageIndex: 0,
      },
    }));
  };

  const onSortingChange = (newSorting) => {
    setTableState((prevState) => ({
      ...prevState,
      sorting: newSorting,
    }));
  };

  const onPaginationChange = (newPagination) => {
    setTableState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        ...newPagination,
      },
    }));
  };

  const onSearchChange = (newSearch) => {
    setTableState((prevState) => ({
      ...prevState,
      search: newSearch,
    }));
  };

  return {
    data: data.data,
    meta: data.meta,
    isLoading: isLoading,
    responseFilters,
    selectedFilter: tableState.filter,
    totalPages: tableState.pagination.totalPages,
    pagination: tableState.pagination,
    search: tableState.search,
    onFilterChange,
    onSortingChange,
    onPaginationChange,
    onSearchChange,
    refetch,
  };
};

export default useTable;
