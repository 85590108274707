import {
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Grid,
  GridItem,
  Button,
  useDisclosure,
  Stack,
} from '@chakra-ui/react';
import Select from 'components/Dashboard/Select';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CreateButtonsContainer from '../CreateButtonsContainer';
import { Link } from 'react-router-dom';
import DatePicker from 'components/shared/Inputs/DatePicker';
import { getFormSelectDefaultValue } from 'utils/select';
import WithAddNewButton from '../WithAddNewButton';
import { useMutation, useQuery } from 'react-query';
import { createAddress } from 'api/Dashboard/addresses';
import AddressFormDrawer from '../Orders/AddressFormDrawer';
import POCDetailsSection from '../Orders/POCDetailsSection';
import { getWarehouseTimeSlots } from 'api/Dashboard/warehouses';
import { formatDate } from 'utils/date';
import { translatePickupTime } from 'pages/Dashboard/Transportations/TransportationSummary';

const OutboundForm = ({
  onSubmit,
  defaultValues = {},
  outboundTypeOptions,
  destinationOptions,
  warehouseOptions,
  isLoading,
  backendErrors,
  onAddNewSuccess,
}) => {
  const { t } = useTranslation();

  const {
    isOpen: isAddressOpen,
    onOpen: onAddressOpen,
    onClose: onAddressClose,
  } = useDisclosure();

  const { handleSubmit, formState, control, register, setValue, watch } =
    useForm({
      defaultValues,
    });

  const errors = { ...formState.errors, ...backendErrors };

  const watchScheduleOn = watch('schedule_at');

  const watchOrigin = warehouseOptions?.find(
    (warehouseOption) => warehouseOption.value === watch('origin_id')
  );
  const watchDestination = watch('destination_id');

  const enablePickupTime = !!watchOrigin && !!watchScheduleOn;

  const {
    data: warehouseTimeSlots = {},
    isLoading: isWarehouseTimeSlotsLoading,
  } = useQuery(
    ['warehouse-time-slots', watchOrigin, watchScheduleOn],
    () =>
      getWarehouseTimeSlots({
        address_id: watchOrigin.value,
        selected_date: formatDate(watchScheduleOn, 'dd/MM/yyyy'),
      }),
    {
      enabled: enablePickupTime,
    }
  );

  const pickupTimeSlots = warehouseTimeSlots.time_slots;

  const pickupTimeOptions = pickupTimeSlots?.map((slot) => ({
    label: translatePickupTime(slot),
    value: slot,
  }));

  const {
    mutate: createAddressMutation,
    isLoading: isCreateAddressLoading,
    error: createAddressError,
  } = useMutation(createAddress, {
    onSuccess: async (response) => {
      await onAddNewSuccess();
      setValue('destination_id', response.data.data.id);
      onAddressClose();
    },
  });

  return (
    <>
      <Stack spacing={6}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid templateColumns="repeat(7, 1fr)" gap={6}>
            <GridItem colSpan={{ base: 7, md: 7 }}>
              <Card size="lg">
                <CardHeader>
                  <SectionTitle title={t('outboundDetails')} />
                </CardHeader>
                <CardBody>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                    <Controller
                      control={control}
                      name="carrier_id"
                      rules={{ required: 'thisFieldIsRequired' }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label={t('outboundType')}
                          placeholder={t('pleaseSelectYourOutboundType')}
                          options={outboundTypeOptions}
                          onChange={({ value }) => onChange(value)}
                          error={errors?.carrier_id}
                          defaultValue={getFormSelectDefaultValue(
                            defaultValues.carrier_id,
                            outboundTypeOptions
                          )}
                          required
                        />
                      )}
                    />
                    <WithAddNewButton onClick={onAddressOpen}>
                      <Controller
                        control={control}
                        name="destination_id"
                        rules={{ required: 'thisFieldIsRequired' }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            label={t('destination')}
                            placeholder={t('pleaseSelectTheOrigin')}
                            options={destinationOptions}
                            onChange={({ value }) => onChange(value)}
                            error={errors?.destination_id}
                            defaultValue={getFormSelectDefaultValue(
                              defaultValues.destination_id,
                              destinationOptions
                            )}
                            value={getFormSelectDefaultValue(
                              value,
                              destinationOptions
                            )}
                            required
                          />
                        )}
                      />
                    </WithAddNewButton>
                    <Controller
                      control={control}
                      name="origin_id"
                      rules={{ required: 'thisFieldIsRequired' }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label={t('warehouse')}
                          placeholder={t('pleaseSelectTheWarehouse')}
                          options={warehouseOptions}
                          onChange={({ value }) => onChange(value)}
                          error={errors?.origin_id}
                          defaultValue={getFormSelectDefaultValue(
                            defaultValues.origin_id,
                            warehouseOptions
                          )}
                          required
                        />
                      )}
                    />

                    <DatePicker
                      name="schedule_at"
                      label={t('deliveryDate')}
                      register={register}
                      setValue={setValue}
                      errors={errors}
                      required="thisFieldIsRequired"
                      minDate={new Date()}
                      formValue={watchScheduleOn}
                      control={control}
                    />

                    {((defaultValues.time_of_pickup &&
                      !isWarehouseTimeSlotsLoading) ||
                      !defaultValues.time_of_pickup) && (
                      <Controller
                        control={control}
                        name="time_of_pickup"
                        rules={{ required: 'thisFieldIsRequired' }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            label={t('pickupTime')}
                            placeholder={t('timeOfPickup')}
                            options={pickupTimeOptions}
                            onChange={({ value }) => onChange(value)}
                            error={errors?.time_of_pickup}
                            isDisabled={!enablePickupTime}
                            defaultValue={getFormSelectDefaultValue(
                              defaultValues.time_of_pickup,
                              pickupTimeOptions
                            )}
                            required
                          />
                        )}
                      />
                    )}
                  </SimpleGrid>
                </CardBody>
              </Card>
            </GridItem>
          </Grid>

          <CreateButtonsContainer>
            <Button
              colorScheme="red"
              variant="outline"
              bgColor="white"
              size="lg"
              minWidth="124px"
              isDisabled={isLoading}
              as={Link}
              to="/outbounds"
            >
              {t('cancel')}
            </Button>
            <Button
              colorScheme="primary"
              size="lg"
              minWidth="124px"
              type="submit"
              textTransform="capitalize"
              isLoading={isLoading}
            >
              {t('next')}
            </Button>
          </CreateButtonsContainer>
        </form>

        {watchDestination && (
          <POCDetailsSection id={watchDestination} isOutbound />
        )}
        {watchOrigin && (
          <POCDetailsSection id={watchOrigin.id} isWarehouse isOutbound />
        )}
      </Stack>
      <AddressFormDrawer
        isOpen={isAddressOpen}
        onClose={onAddressClose}
        isLoading={isCreateAddressLoading}
        onSubmit={(data) => createAddressMutation(data)}
        backendErrors={createAddressError}
      />
    </>
  );
};

export default OutboundForm;
