import {
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { useMutation, useQuery } from 'react-query';
import { getOrganization } from 'api/auth';
import { createAddress } from 'api/Dashboard/addresses';
import { getLocalStorage } from 'utils/localStorage';
import OrganizationInfoForm from 'components/Dashboard/Organization/OrganizationInfoForm';
// import LocationDetailsForm from 'components/Dashboard/LocationDetailsForm';
import { updateOrganization } from 'api/Dashboard/organization';

const Organization = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const user = getLocalStorage('user');

  const { data: organizationData = {}, isLoading: isOrganizationDataLoading } =
    useQuery(['organization'], () => getOrganization(user.id), {
      refetchOnMount: true,
      cacheTime: 100,
    });

  const mobileNumber = organizationData.mobile_number;
  const expiryThreshold = organizationData.inventory_expiry_threshold_in_days;

  const personalInfoDefaultValues = {
    logo: [{ base64: organizationData?.logo }],
    name: organizationData.name ?? '',
    mobile_number: mobileNumber ? '' + mobileNumber : '',
    inventory_expiry_threshold_in_days: expiryThreshold ? '' + expiryThreshold : '',
  };

  const handleUpdateSuccess = () => {
    toast({
      title: t('organizationUpdatedSuccessfully'),
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const {
    mutate: updateOrganizationInfo,
    isLoading: isUpdateOrganizationInfo,
    error: updateOrganizationInfoError,
  } = useMutation(
    (organization) => updateOrganization(organizationData.id, organization),
    {
      onSuccess: (data) => {
        handleUpdateSuccess();
      },
    }
  );

  const { mutate: createAddressMutation, isLoading: isCreateAddressLoading } =
    useMutation(createAddress, {
      onSuccess: () => {
        handleUpdateSuccess();
      },
    });

  const handleAddressSubmit = (data) => {
    const payload = {
      address: data.address,
      pocs_attributes: [
        {
          name: organizationData.name,
          mobile_number: organizationData.mobile_number,
          main: true,
        },
      ],
    };

    createAddressMutation(payload);
  };

  const addressDefaultValue = organizationData.addresses;

  const handleOrganizationSubmit = (data) => {
    const newLogo = data.logo[0];

    const logoPayload =
      newLogo.base64 === organizationData?.logo
        ? undefined
        : { ...newLogo, base64: newLogo.base64?.split(',')[1] };

    const organization = {
      ...data,
      logo: logoPayload,
    };

    updateOrganizationInfo(organization);
  };

  const handleDeleteLogo = () => {
    const organization = {
      logo: {
        _destroy: '1',
      },
    };

    updateOrganizationInfo(organization);
  };

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={6} paddingTop={2}>
      <GridItem colSpan={{ base: 6, md: 6 }}>
        <Card size="lg">
          <CardHeader>
            <SectionTitle title={t('organizationInformation')} />
          </CardHeader>
          <CardBody>
            {isOrganizationDataLoading ? (
              <Spinner color="primary.500" />
            ) : (
              <OrganizationInfoForm
                onSubmit={handleOrganizationSubmit}
                defaultValues={personalInfoDefaultValues}
                isLoading={isUpdateOrganizationInfo}
                onDelete={handleDeleteLogo}
                backendErrors={
                  updateOrganizationInfoError?.response.data.errors
                }
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      {/* <GridItem colSpan={{ base: 6, md: 2 }}>
        <Card borderRadius="xl" size="lg">
          <CardHeader>
            <SectionTitle title={t('mainOfficeDetails')} />
          </CardHeader>
          <CardBody>
            <LocationDetailsForm
              onSubmit={handleAddressSubmit}
              defaultValues={{ address: addressDefaultValue ?? {} }}
              isUpdate={false}
              isLoading={isCreateAddressLoading}
              hideCity
            />
          </CardBody>
        </Card>
      </GridItem> */}
    </Grid>
  );
};

export default Organization;
