import { Box, Flex, Hide, Show, Stack } from '@chakra-ui/react';
import { createInbound, requestNewInbound } from 'api/Dashboard/inbounds';
import BackToListButton from 'components/Dashboard/BackToListButton';
import InboundForm from 'components/Dashboard/Inbounds/InboundForm';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import { inboundTypeEnum } from 'constants/dashboard';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { adjustToUTC } from 'utils/date';
import { flattenErrorObject } from 'utils/formError';
import { mapSelectListToFormOptions } from 'utils/select';

const InboundCreate = () => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();
  const navigate = useNavigate();

  const { data = {}, refetch } = useQuery(
    ['request-new-inbound'],
    requestNewInbound
  );

  const {
    mutate: createInboundMutation,
    isLoading: isCreateInboundLoading,
    error,
  } = useMutation(createInbound, {
    onSuccess: (data) => {
      navigate(`/inbounds/${data.data.id}/assign`);
    },
  });

  const inboundTypeOptions = data.select_options?.inbound_types.map(
    ([label, value]) => {
      const labelKey = Object.keys(inboundTypeEnum).find(
        (key) => inboundTypeEnum[key] === value
      );

      return {
        label: t(`orderCarrier.${labelKey}`),
        value,
      };
    }
  );

  const originOptions = mapSelectListToFormOptions(
    data.select_options?.origins
  );

  const warehouseOptions = data.select_options?.warehouses.map((warehouse) => ({
    label: warehouse.name,
    value: warehouse.address_id,
    id: warehouse.id,
  }));

  const supplierOptions = mapSelectListToFormOptions(
    data.select_options?.suppliers
  );

  const handleSubmit = (data) => {
    const payload = {
      ...data,
      schedule_at: adjustToUTC(data.schedule_at),
    };

    createInboundMutation(payload);
  };

  return (
    <Stack spacing={6}>
      <Box>
        <Show below="md">
          <Box mb={2} mt={4}>
            <PageTitle title={pageTitle} fontWeight={600} />
          </Box>
        </Show>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Hide below="md">
            <BackToListButton title={t('inboundsList')} url="/inbounds" />
          </Hide>
        </Flex>
      </Box>

      <InboundForm
        onSubmit={handleSubmit}
        inboundTypeOptions={inboundTypeOptions}
        originOptions={originOptions}
        warehouseOptions={warehouseOptions}
        supplierOptions={supplierOptions}
        isLoading={isCreateInboundLoading}
        backendErrors={flattenErrorObject(error?.response?.data.errors)}
        onAddNewSuccess={async () => {
          await refetch();
        }}
      />
    </Stack>
  );
};

export default InboundCreate;
