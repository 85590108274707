import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SlickSlider from 'react-slick';
import { Image } from '@chakra-ui/react';
import NoImgPlaceholder from 'assets/images/no-image-placeholder.png';

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Carousel = ({ slides, boxSize, imageProps }) => {
  const defaultProps = {
    objectFit: 'cover',
    width: 'full',
    alt: 'No Image',
    boxSize: boxSize,
  };

  if (!slides.length) {
    return <Image src={NoImgPlaceholder} {...defaultProps} {...imageProps} />;
  }

  return (
    <SlickSlider {...settings}>
      {slides?.map((url, index) => (
        <Image
          key={index}
          src={url}
          fallbackSrc={NoImgPlaceholder}
          {...defaultProps}
          {...imageProps}
        />
      ))}
    </SlickSlider>
  );
};

export default Carousel;
