import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import InputField from 'components/shared/Inputs/InputField';
import Button from 'components/shared/Button';

const Form = ({ onSubmit, isLoading, responseError }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = (data) => {
    onSubmit(data);
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(submit)} noValidate>
      <InputField
        id="email"
        name="email"
        type="email"
        autoComplete="email"
        label={t('email')}
        placeholder="name@sirdab.co"
        register={register}
        errors={errors}
        required={'validation.emailRequired'}
        pattern={{
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: 'validation.invalidEmail',
        }}
      />

      {responseError && (
        <div className="mt-1 text-sm text-red-700">{responseError}</div>
      )}

      <Button
        label={t('continue')}
        type="submit"
        disabled={errors.agree?.message}
        isLoading={isLoading}
      />

      <p className="mt-2">
        <Link
          to="/sign-in"
          className="font-semibold text-sm text-primary hover:text-primary/[.85]"
        >
          {t('returnToLogin')}
        </Link>
      </p>
    </form>
  );
};

export default Form;
