import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  Table,
  TableContainer,
  Tbody,
  Th as ChakraTh,
  Td as ChakraTd,
  Thead,
  Tr,
  Text,
  Button,
  Box,
  Flex,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import StepperInput from 'components/shared/Inputs/StepperInput';
import { Trans, useTranslation } from 'react-i18next';
import useLanguage from 'hooks/useLanguage';
import Tooltip from 'components/shared/Tooltip';
import CalendlyPopupText from 'components/shared/CalendlyPopupText';

const Th = ({ children, ...props }) => (
  <ChakraTh {...props} color="gray.500" paddingInline={2}>
    {children}
  </ChakraTh>
);

const Td = ({ children, ...props }) => (
  <ChakraTd {...props} paddingInline={2}>
    {children}
  </ChakraTd>
);

const getPriceRangeByValue = (priceRanges, value) => {
  return priceRanges.find(
    (priceRange) => value >= priceRange.min && value <= (priceRange.max ?? 9999)
  );
};

const hasValueGreaterThanZero = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] > 0) {
      return true;
    }
  }
  return false;
};

const Quote = ({
  onChange,
  defaultValues,
  fees,
  setFees,
  services,
  isLoading,
  disabled,
  disableSubmit,
  ignorePriceRange,
  submitText,
  noScroll,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm({ defaultValues });

  const feesRef = useRef(null);

  useEffect(() => {
    if (fees) {
      setTimeout(() => {
        feesRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [fees]);

  useEffect(() => {
    if (!onChange) {
      return;
    }

    const subscription = watch((data, { name, type }) => {
      if (name === undefined) {
        return;
      }
      const value = data[name];
      onChange(name, !isNaN(value) ? +value : value);
    });

    return () => subscription.unsubscribe();
  }, [onChange, watch]);

  if (isLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  if (!services) {
    return null;
  }

  const onFormSubmit = (data) => {
    const prices = Object.keys(data).map((key) => {
      const id = key.split('-')[1];
      const value = +data[key];
      const service = services.find((service) => service.id === +id);

      if (ignorePriceRange) {
        return value * (service.price_per_unit_cents / 100);
      }

      const priceInCents = getPriceRangeByValue(
        service.price_ranges,
        value
      )?.price_in_cents;

      return value * (priceInCents / 100);
    });

    const pricesSum = prices.reduce((partialSum, a) => partialSum + a, 0);

    setFees(pricesSum, data);
  };

  const isAllZero = !hasValueGreaterThanZero(getValues());

  const hasErrors = !!Object.keys(errors).length;

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <TableContainer maxHeight={noScroll ? 'auto' : '370'} overflowY="auto">
        <Table variant="simple" className="mobile-optimized-table">
          <Thead>
            <Tr>
              <Th>{t('service')}</Th>
              <Th>{t('numberOfPallets')}</Th>
              <Th>{t('price')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {services.map(
              (
                {
                  name,
                  ar_name,
                  id,
                  price_ranges,
                  unit_type,
                  ar_unit_type,
                  description,
                  description_ar,
                  price_per_unit_cents,
                  minimum_quantity,
                },
                index
              ) => {
                const inputName = `service-${id}`;
                const value = +getValues(inputName) || 0;

                const currentPriceRange = ignorePriceRange
                  ? { price_in_cents: price_per_unit_cents }
                  : getPriceRangeByValue(price_ranges, value);

                const price = (
                  currentPriceRange?.price_in_cents / 100
                )?.toLocaleString('en');

                const localUnitType =
                  language === 'ar' ? ar_unit_type : unit_type;

                const priceLabel = `${price} ${t('SAR')} ${
                  localUnitType ? `/ ${localUnitType}` : ''
                }`;

                const localName = language === 'ar' ? ar_name : name;

                const localDescription =
                  language === 'ar' ? description_ar : description;

                return (
                  <Tr key={index}>
                    <Td>
                      {localName}
                      {localDescription && (
                        <Box display="inline-block" marginInlineStart="2">
                          <Tooltip
                            label={localDescription}
                            icon={<InfoIcon boxSize="14px" color="#5B6B93" />}
                            placement="top"
                          />
                        </Box>
                      )}
                    </Td>
                    <Td>
                      <fieldset disabled={disabled ? 'disabled' : ''}>
                        <StepperInput
                          name={inputName}
                          required="thisFieldIsRequired"
                          register={register}
                          defaultValue={defaultValues[inputName]}
                          setValue={setValue}
                          trigger={trigger}
                          errors={errors}
                          minErrorMessage="minIs"
                          min={0}
                          isDisabled={disabled}
                          validate={(value) => {
                            if (value > 0) {
                              return (
                                value >= minimum_quantity ||
                                `${t('minIs')} ${minimum_quantity}`
                              );
                            }
                          }}
                        />
                      </fieldset>
                    </Td>
                    <Td fontWeight="500">
                      {currentPriceRange?.included ? (
                        <Text color="primary.500">{t('Included')}</Text>
                      ) : (
                        priceLabel
                      )}
                    </Td>
                  </Tr>
                );
              }
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <Center>
        <Box maxW="668px" width="full">
          <Button
            type="submit"
            colorScheme="primary"
            size="lg"
            width="full"
            marginTop={{ base: '4', sm: '8' }}
            isDisabled={disableSubmit || isAllZero || hasErrors}
          >
            {submitText || t('calculateFees')}
          </Button>

          {fees !== undefined && fees !== null && (
            <>
              <Flex
                fontSize="2xl"
                justifyContent="center"
                gap={{ base: '1', md: '2' }}
                paddingBlock={{ base: '6', md: '8' }}
                paddingInline="4"
                marginTop="4"
                border="1px dashed"
                borderColor="primary.500"
                borderRadius="2xl"
                bg="#F9FAFB"
                flexWrap="wrap"
                ref={feesRef}
              >
                <Text color="primary.500" fontWeight="600">
                  {ignorePriceRange ? t('totalFees') : t('expectedFees')}
                </Text>
                <Box fontWeight="700">
                  {fees.toLocaleString('en')} {t('SAR')}
                  <Text fontSize="sm" display="inline" fontWeight="500">
                    /{t('month')}
                  </Text>
                </Box>
              </Flex>
              {!ignorePriceRange && (
                <Text color="gray.600" fontSize="sm" marginTop="4">
                  <Trans
                    i18nKey="quoteFeesHint"
                    t={t}
                    components={{
                      linkTag: (
                        <CalendlyPopupText
                          content={
                            <Button
                              as="span"
                              variant="link"
                              colorScheme="primary"
                              fontWeight="500"
                              size="sm"
                            >
                              {t('getHelp')}
                            </Button>
                          }
                        />
                      ),
                    }}
                  />
                </Text>
              )}
            </>
          )}
        </Box>
      </Center>
    </form>
  );
};

export default Quote;
