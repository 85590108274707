import { useTranslation } from 'react-i18next';
import StatusTag from './StatusTag';

const statusTagColors = {
  completed: 'green',
  complete: 'green',
  created: 'blue',
  in_review: 'yellow',
  draft: 'purple',
  cancelled: 'red',
  processing: 'primary',
  assigned: 'blue',
  ready_for_delivery: 'gray',
  in_transit: 'gray',
  on_transit: 'gray',
  put_away: 'gray',
};

const OrderStatusTag = ({ status }) => {
  const { t } = useTranslation();

  return (
    <StatusTag colorScheme={statusTagColors[status]}>
      {t(`orderStatus.${status}`)}
    </StatusTag>
  );
};

export default OrderStatusTag;
