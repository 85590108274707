import {
  Box,
  Stack,
  Text,
  Flex,
  IconButton,
  FormLabel,
  Input,
  HStack,
} from '@chakra-ui/react';

import { toBase64Handler } from 'components/shared/Inputs/FileUpload/utils';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DescriptionIcon } from 'assets/icons/description.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as CloudDownloadIcon } from 'assets/icons/cloud-download.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as FileUploadIcon } from 'assets/icons/file-upload.svg';
import styled from '@emotion/styled';

const StyledEyeIcon = styled(EyeIcon)`
  path {
    fill: #03989e;
  }
`;

const downloadFile = async (file) => {
  try {
    const response = await fetch(file.url);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

const FileList = ({ files = [], onDelete }) => {
  const { t } = useTranslation();

  if (files.length === 0) {
    return null;
  }

  return (
    <Stack spacing={3} maxHeight="285px" overflowY="auto">
      {files.map((file, index) => (
        <Flex
          key={`${file.name}-${index}`}
          alignItems="center"
          justifyContent="space-between"
          bg="primary.50"
          padding={2}
          borderRadius="xl"
          gap={3}
        >
          <Flex gap={3} alignItems="center">
            <Box p={2} background="#fff" borderRadius="10px">
              <DescriptionIcon />
            </Box>
            <Stack spacing={0} fontSize="xs">
              <Text fontWeight={600} color="primary.700">
                {file.name}
              </Text>
              <Text color="primary.600" textTransform="uppercase">
                {file.size / 1000} {t('kb')}
              </Text>
            </Stack>
          </Flex>
          <HStack marginInlineEnd={3}>
            <IconButton
              variant="ghost"
              size="xs"
              onClick={() => onDelete(file.id)}
              icon={<TrashIcon width="24px" height="24px" />}
            />
            <IconButton
              variant="ghost"
              size="xs"
              icon={<CloudDownloadIcon width="24px" height="24px" />}
              onClick={() => downloadFile(file)}
            />
            <IconButton
              variant="ghost"
              size="xs"
              icon={<StyledEyeIcon width="24px" height="24px" />}
              as="a"
              target="_blank"
              href={file.url}
            />
          </HStack>
        </Flex>
      ))}
    </Stack>
  );
};

export const FileInput = ({
  onChange,
  value,
  multiple = true,
  placeholder = 'selectFilesToUpload',
  name = `dropzone-file-${Date.now()}`,
  accept,
}) => {
  const { t } = useTranslation();

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        handleFileUpload(item.files);
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
        };
      },
    }),
    []
  );

  const handleFileUpload = async (files) => {
    const uploadedFilesAsArray = [...files];
    const attachmentsBase64 = await toBase64Handler(uploadedFilesAsArray);

    onChange(attachmentsBase64);
  };

  const isActive = isOver;

  return (
    <Flex ref={drop} align="center" justify="center" w="full">
      <FormLabel
        htmlFor={name}
        border="1px dashed"
        borderColor="gray.300"
        rounded="xl"
        cursor="pointer"
        _hover={{ bg: 'gray.50', borderColor: 'gray.400' }}
        _disabled={{
          opacity: 0.6,
        }}
        _invalid={{ borderColor: 'red' }}
        w="full"
        h="120px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        margin="0"
        gap={3}
      >
        <FileUploadIcon />
        <Box>
          <Text fontSize="sm" color="primary.500">
            {t(isActive ? 'releaseToUpload' : placeholder)}
          </Text>
          {!isActive && (
            <Text fontSize="xs" color="gray.600">
              {t('orDragAndDropHere')}
            </Text>
          )}
        </Box>
        <Input
          name={name}
          id={name}
          type="file"
          accept={accept}
          multiple={multiple}
          onChange={(e) => {
            handleFileUpload(e.target.files);
          }}
          display="none"
        />
      </FormLabel>
    </Flex>
  );
};

export const AttachmentUpload = ({ onChange, onDelete, defaultValue = [] }) => {
  return (
    <Stack spacing={6}>
      <FileList files={defaultValue} onDelete={onDelete} />

      <FileInput onChange={onChange} />
    </Stack>
  );
};
