import api from 'utils/axios';

export const getInbounds = async (searchParams) => {
  try {
    const response = await api(`/merchants/inbounds?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getInboundById = async (id) => {
  try {
    const response = await api(`/merchants/inbounds/${id}`);
    const { data } = response;

    return {
      data: data.data,
      chargeable_activities: data.chargeable_activities,
    };
  } catch (error) {
    throw error;
  }
};

export const getInboundSKUs = async (id, searchParams) => {
  try {
    const response = await api(
      `/merchants/inbounds/${id}/skus${searchParams ? `?${searchParams}` : ''}`
    );
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const cancelInbound = async (id) => {
  try {
    const response = await api.patch(`/merchants/inbounds/${id}/cancel`);
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const downloadReceivingSlip = async (id) => {
  try {
    const response = await api(`/merchants/inbounds/${id}/receiving_slip`, {
      responseType: 'blob',
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadSKULabels = async (id, size) => {
  try {
    const response = await api(
      `/merchants/inbounds/${id}/generated_labels?size=${size}`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const addSkuToInbound = async (id, inbound_sku) => {
  try {
    const response = await api.post(`/merchants/inbounds/${id}/add_sku`, {
      inbound_sku: {
        sku_id: inbound_sku.sku_id,
        merchant_sku_quantity: inbound_sku.quantity,
        merchant_sku_packaging: inbound_sku.packaging,
        merchant_sku_expiry: inbound_sku.expiry_date,
      },
    });
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const removeInboundSKU = async ({ id, skuID }) => {
  try {
    const response = await api.delete(
      `/merchants/inbounds/${id}/remove_sku/${skuID}`
    );
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const requestNewInbound = async (id) => {
  try {
    const response = await api('/merchants/inbounds/new');

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createInbound = async (inbound) => {
  try {
    const response = await api.post('/merchants/inbounds', { inbound });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateInbound = async (id, inbound) => {
  try {
    const response = await api.patch(`/merchants/inbounds/${id}`, { inbound });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitInboundForReview = async (id) => {
  try {
    const response = await api.post(`/merchants/inbounds/${id}/submit`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChargeableActivitiesSummary = async (id, searchParams) => {
  try {
    const response = await api(
      `/merchants/inbounds/${id}/chargeable_summary_tab?${searchParams}`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getChargeableActivitiesByInbound = async (id, searchParams) => {
  try {
    const response = await api(
      `/merchants/inbounds/${id}/chargeable_by_inbound_tab?${searchParams}`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getChargeableActivitiesBySKU = async (id, searchParams) => {
  try {
    const response = await api(
      `/merchants/inbounds/${id}/chargeable_by_sku_tab?${searchParams}`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};
