import { Box, Button, Center, Stack } from '@chakra-ui/react';
import { approveQuote, getQuote } from 'api/onboarding';
import BackToListButton from 'components/Dashboard/BackToListButton';
import Quote from 'components/Onboarding/Quote/Quote';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { quoteLineItemsToQuote } from 'utils/quote';
import { useNavigate } from 'react-router-dom';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';

const ReviewQuote = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const warehousesSearch = location.state?.warehousesSearch;

  const [quoteState, setQuoteState] = useState({});

  const { data, isLoading } = useQuery(['quote', id], () => getQuote(id), {
    onSuccess: (data) => {
      setQuoteState((prevState) => ({
        ...prevState,
        quote: quoteLineItemsToQuote(data.quote_line_items),
        fees: data.total / 100,
      }));
    },
  });

  const { mutate: approveQuoteMutation, isLoading: isApproveQuoteLoading } =
    useMutation(approveQuote, {
      onSuccess: (data) => {
        navigate('/warehouses/success');
      },
    });

  const isQuoteApproved = data?.active;

  const quoteLineItems = data?.quote_line_items;
  const quoteDefaultValue = quoteLineItemsToQuote(quoteLineItems);
  const services = quoteLineItems?.map((quoteLineItem) => ({
    ...quoteLineItem.warehouse_service,
    price_per_unit_cents: quoteLineItem.price_per_unit_cents,
  }));

  const onQuoteChange = (name, value) => {
    setQuoteState((prevState) => ({
      ...prevState,
      fees: undefined,
    }));
  };

  const handleFeesUpdate = (newFees, quote) => {
    setQuoteState((prevState) => ({
      fees: newFees,
      quote,
    }));
  };

  const handleApproveQuote = () => {
    const newQuoteLineItems = Object.keys(quoteState.quote).map((key) => {
      const value = +quoteState.quote[key];
      const serviceId = +key.split('-')[1];

      const id = quoteLineItems.find(
        (item) => item.warehouse_service_id === serviceId
      ).id;

      return {
        id,
        quantity: value,
      };
    });

    approveQuoteMutation({
      quoteId: id,
      quoteLineItems: newQuoteLineItems,
    });
  };

  return (
    <Stack spacing={6}>
      <Box>
        <BackToListButton
          title={t('listOfWarehouses')}
          onClick={() => {
            navigate('/warehouses', {
              state: { warehousesSearch },
            });
          }}
        />
      </Box>

      <Box bg="white" p={{ base: 4, md: 6 }} borderRadius="2xl">
        <Box mb={8}>
          <SectionTitle title={t('reviewAndApproveQuote')} hideDivider />
        </Box>
        <Quote
          onChange={onQuoteChange}
          setFees={handleFeesUpdate}
          fees={quoteState.fees}
          defaultValues={quoteDefaultValue ?? {}}
          services={services}
          disabled={isApproveQuoteLoading || isQuoteApproved}
          disableSubmit={!isNaN(quoteState.fees) || isQuoteApproved}
          isLoading={isLoading}
          noScroll
        />
        <Center>
          <Box maxW="668px" width="full">
            {!isNaN(quoteState.fees) && !isQuoteApproved && (
              <Button
                colorScheme="primary"
                w="full"
                size="lg"
                marginTop="4"
                onClick={handleApproveQuote}
                isDisabled={isApproveQuoteLoading || isQuoteApproved}
              >
                {t('approveQuote')}
              </Button>
            )}
          </Box>
        </Center>
      </Box>
    </Stack>
  );
};

export default ReviewQuote;
